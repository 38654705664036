import { Controller } from "stimulus"

function formatMoney(money) {
    return new Intl.NumberFormat('en-US',{ style: 'currency', currency: 'USD' }).format(money);
}

export default class extends Controller {
  static targets = ["fuelTotal","deductible","totalPrice"]

  connect() {
    console.log("connected to checkout controller", this.data.get("price"))
  }

  quantityChanged(e) {
    let quantity = e.currentTarget.value
    let total = quantity * this.data.get("price")
    this.fuelTotalTarget.value = total
    this.setTotalPrice()
  }

  deductibleChanged(e) {
    this.setTotalPrice()
  }

  setTotalPrice() {
    this.totalPriceTarget.innerHTML = formatMoney(parseFloat(this.fuelTotalTarget.value || 0) + parseFloat(this.deductibleTarget.value || 0))
  }

}
