import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import { useForm } from "react-hook-form";
import client from "../../src/client"
import Button from '../Button'
import NumberFormat from "react-number-format";
import {parseISO} from 'date-fns'



function PhoneInput({register,name,setValue,value}) {


    useEffect(()=>{
        register(name)
    },[register])

    function handleChange(e) {
        setValue(name,e.target.value)
    }

    return (
        <NumberFormat onChange={handleChange} defaultValue={value} format="(###) ###-####" allowEmptyFormatting mask="_" className="form-control" placeholder="(888) 888-8888" type="tel" />
    )
}


function DateOfBirthInput({register,name,setValue,value}) {

    const [month,setMonth] = useState('1')
    const [day,setDay] = useState('')
    const [year,setYear] = useState('')

    
    useEffect(()=>{

        if(day && month && year) {
            try {

                let date = new Date(year,(month-1),day)
                if(setValue) {
                    setValue(name,date)
                }

            } catch(e) {
                console.error(e)
            }
        }

    },[day,month,year])

    useEffect(()=>{
        register(name)
    },[register])

    useEffect(()=>{

        if(value) {
            try {

                let date = parseISO(value)

                setMonth( date.getMonth()+1 )
                setDay( date.getDate() )
                setYear( date.getFullYear() )

            } catch(e) {
                console.error(e)
            }
        }

    },[value])


    return (
        <div className="flex">

            <div className="w-1/2 mr-2">
                <select value={month} onChange={(e)=>setMonth(e.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                        <option value={1}>January</option>
                        <option value={2}>February</option>
                        <option value={3}>March</option>
                        <option value={4}>April</option>
                        <option value={5}>May</option>
                        <option value={6}>June</option>
                        <option value={7}>July</option>
                        <option value={8}>August</option>
                        <option value={9}>September</option>
                        <option value={10}>October</option>
                        <option value={11}>November</option>
                        <option value={12}>December</option>
                </select>
            </div>
            <div className="w-1/4 mr-2">
                <input value={day} onChange={(e)=>setDay(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Day" />
            </div>
            <div className="w-1/4">
                <input value={year} onChange={(e)=>setYear(e.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" placeholder="Year" />
            </div>
        </div>
    )
}


export default function DriverForm({onSave}) {

    const { state, context } = useReservationContext()
    const { register, handleSubmit, watch, errors, reset, setValue,getValues } = useForm();
    const [serverErrors,setErrors] = useState()

    let customer = state.customer

    useEffect(()=>{

        if(state.driver) {
            console.log("populate form driver=", state.driver )
            setValue("first_name", state.driver.firstName)
            setValue("last_name", state.driver.lastName)
            setValue("phone", state.driver.phone)
            setValue("email", state.driver.email)
            setValue("dob", state.driver.dob)
            setValue("license_number", state.driver.licenseNumber)
            setValue("emergency_name", state.driver.emergencyContact)
            setValue("emergency_phone", state.driver.emergencyPhone)
        }

    },[state.driver])

    const onSubmit = async (data) => {

        try {

            let r;
            data.customer_id = state.customer?.id

            if(state.driver?.id) {
                r = await client.put(`/api/v1/drivers/${state.driver.id}`,{driver: data})
            } else {
                r = await client.post(`/api/v1/drivers`,{driver: data})
            }

            if(r && onSave) {
                onSave(r.data)
            }
    
        } catch(e) {
            if(e.isAxiosError) {
                console.error(e.response.data.errors)
                setErrors(e.response.data.errors)
            } else {
                console.error(e)
            }
        }
              
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>


            {serverErrors &&
                <div className="alert alert-error mb-6" role="alert">
                    <div className="flex items-start justify-start">
                        <div>
                        <h4 className="font-black text-sm uppercase tracking">
                            Validation Errors
                        </h4>
                            <ul>
                                {serverErrors.map((error)=>{
                                    return (
                                        <li>{error}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>                
            }

            <div>
                <div className="sm:grid sm:grid-cols-2 mb-5 gap-2">
                    <div className="form-group">
                        <label>Driver First Name</label>
                        <input name="first_name" ref={register} className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Driver Last Name</label>
                        <input name="last_name" ref={register} className="form-control" />
                    </div>


                    <div class="form-group">
                        <label>Driver Phone Number</label>
                        <PhoneInput name="phone" register={register} setValue={setValue} value={state.driver?.phone}/>
                    </div>

                    <div className="form-group">
                        <label>Driver Email</label>
                        <input name="email" ref={register} className="form-control" />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-2 mb-5 gap-2">
                    <div className="form-group">
                        <label>DOB</label>
                        <DateOfBirthInput name="dob" register={register} setValue={setValue} value={state.driver?.dob}/>
                    </div>
                    <div className="form-group">
                        <label>Driver License Number</label>
                        <input name="license_number" ref={register} className="form-control" />
                    </div>
                </div>


                <div className="mb-5">
                    <div className="form-group">
                        <label>Experience Level</label>
                        <select ref={register} className="form-control" name="experience_level">
                            <option value="0 - 3 track days">0 - 3 track days</option>
                            <option value="4-7 track days">4-7 track days</option>
                            <option value="8-12 track days">8-12 track days</option>
                            <option value="13+ track days">13+ track days</option>
                        </select>
                    </div>
                </div>


                <div className="sm:grid sm:grid-cols-2 mb-5 gap-2">
                    <div className="form-group">
                        <label>Emergency Contact Name</label>
                        <input ref={register} name="emergency_name" className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>Emergency Contact Phone</label>
                        <PhoneInput name="emergency_phone" register={register} setValue={setValue} value={state.driver?.emergencyPhone}/>
                    </div>
                </div>

                <div className="form-group flex justify-between">
                    <Button block submit>Save</Button>
                </div>                

            </div>
        </form>
    )
}