import { TrackDateProduct, Payment, Reservation, TrackDate, TrackDateVehicle, Customer, Driver, Checkin } from ".";
import { TargetTypes } from "../admin/ProductLineItemForm";

export enum ActionType {
    SetReservation = 'SET_RESERVATION',
    SetCheckin = 'SET_CHECKIN',
    UpdateAddonQuantity = 'UPDATE_ADD_ON_LINE_ITEM_QUANTITY',
    UpdateAddonPrice = 'UPDATE_ADD_ON_LINE_ITEM_PRICE',
    UpdateLineItemPrice = 'UPDATE_LINE_ITEM_PRICE',
    AddLineItem = 'ADD_LINE_ITEM',
    RemoveLineItem = 'REMOVE_LINE_ITEM',
    AddDriver = 'ADD_DRIVER_TO_LINE_ITEM',
    SetCustomer = 'SET_CUSTOMER',
    ClearCustomer = 'CLEAR_CUSTOMER',
    CloseDriverModal = 'CLOSE_DRIVER_MODAL',
    SetDriver = 'SET_DRIVER',
    EditDriver = 'EDIT_DRIVER',
    SelectDriver = 'SELECT_DRIVER',
    OpenPaymentModal = 'OPEN_PAYMENT_MODAL',
    ClosePaymentModal = 'CLOSE_PAYMENT_MODAL',
    UpdateOrderTotals = 'UPDATE_ORDER_TOTALS',
    OpenRefundModal = 'OPEN_REFUND_MODAL',
    CloseRefundModal = 'CLOSE_REFUND_MODAL',
    BeginRefund = 'BEGIN_REFUND',
    UpdateCreditBalance = 'UPDATE_CREDIT_BALANCE',
    ToggleEditMode = 'TOGGLE_EDIT_MODE',
    CancelReservation = 'CANCEL_RESERVATION',
    VoidReservation = 'VOID_RESERVATION',
    SetCancelReservationModal = 'SET_CANCEL_MODAL',
    SetVoidReservationModal = 'SET_VOID_MODAL',
    ClearDiscount = 'CLEAR_DISCOUNT',
    SetDiscount = 'SET_DISCOUNT',
    SetInvalidDiscount = 'SET_INVALID_DISCOUNT',
    ToggleNewTransaction = 'TOGGLE_NEW_TRANSACTION',
    AddProductToLineItem = 'ADD_PRODUCT_TO_LINE_ITEM',
    VipSyncInProgress = 'VIP_SYNC_IN_PROGRESS',
}

interface AddProductToLineItemAction {
    type: typeof ActionType.AddProductToLineItem,
    target: TargetTypes,
    lineItemIndex: number,
    trackDateProduct: TrackDateProduct
}

interface ToggleNewTransactionAction {
    type: typeof ActionType.ToggleNewTransaction,
    lineItemIndex: number
}

interface ClearDiscountAction {
    type: typeof ActionType.ClearDiscount
}

interface SetDiscountAction {
    type: typeof ActionType.SetDiscount
    data: any
}

interface SetInvalidDiscountAction {
    type: typeof ActionType.SetInvalidDiscount
    data: any
}


interface ToggleEditModeAction {
    type: typeof ActionType.ToggleEditMode
}

interface UpdateCreditBalanceAction {
    type: typeof ActionType.UpdateCreditBalance
    amount: number
}

interface SelectDriverAction {
    type: typeof ActionType.SelectDriver,
    id: number
    lineItemIndex: number
}

interface UpdateOrderTotalsAction {
    type: typeof ActionType.UpdateOrderTotals
}

interface OpenPaymentModalAction {
    type: typeof ActionType.OpenPaymentModal
}

interface ClosePaymentModalAction {
    type: typeof ActionType.ClosePaymentModal
}

interface OpenRefundModalAction {
    type: typeof ActionType.OpenRefundModal
}

interface CloseRefundModalAction {
    type: typeof ActionType.CloseRefundModal
}

interface BeginRefundAction {
    type: typeof ActionType.BeginRefund
    payment: Payment
}

interface SetReservationAction {
    type: typeof ActionType.SetReservation,
    payments?: Payment[]
    data: Reservation
}

interface SetCheckinAction {
    type: typeof ActionType.SetCheckin,
    data: Checkin
}

interface UpdateAddonQuantityAction {
    type: typeof ActionType.UpdateAddonQuantity
    trackDateProduct: TrackDateProduct
    target: TargetTypes
    lineItemIndex?: number
    draftLineItem?: boolean
    quantity: number
    unitPrice: number
}

interface UpdateAddonPriceAction {
    type: typeof ActionType.UpdateAddonPrice
    target: TargetTypes
    trackDateProduct: TrackDateProduct
    lineItemIndex?: number
    draftLineItem?: boolean
    price: number
}

interface UpdateLineItemPriceAction {
    type: typeof ActionType.UpdateLineItemPrice
    lineItemIndex: number
    price: number    
}

interface AddLineItemAction {
    type: typeof ActionType.AddLineItem,
    trackDate: TrackDate
    trackDateVehicle: TrackDateVehicle
}

interface RemoveLineItemAction {
    type: typeof ActionType.RemoveLineItem,
    lineItemIndex: number
}

interface AddDriverAction {
    type: typeof ActionType.AddDriver,
    lineItemIndex: number
}

interface SetCustomerAction {
    type: typeof ActionType.SetCustomer,
    customer: Customer
}

interface ClearCustomerAction {
    type: typeof ActionType.ClearCustomer
}

interface CloseDriverModalAction {
    type: typeof ActionType.CloseDriverModal
}

interface VoidReservationAction {
    type: typeof ActionType.VoidReservation
}

interface CancelReservationAction {
    type: typeof ActionType.CancelReservation
}

interface SetDriverAction {
    type: typeof ActionType.SetDriver
    driver: Driver
}

interface EditDriverAction {
    type: typeof ActionType.EditDriver
    driver: Driver
    lineItemIndex: number
}

interface SetCancelReservationModalAction {
    type: typeof ActionType.SetCancelReservationModal
    value: boolean
}

interface SetVoidReservationModalAction {
    type: typeof ActionType.SetVoidReservationModal
    value: boolean
}

interface VipSyncInProgressAction {
    type: typeof ActionType.VipSyncInProgress
    value: boolean
}

export type ReservationFormActions = 
    SetReservationAction
    | UpdateAddonQuantityAction
    | UpdateAddonPriceAction
    | UpdateLineItemPriceAction
    | AddLineItemAction
    | RemoveLineItemAction
    | AddDriverAction
    | SetCustomerAction
    | CloseDriverModalAction
    | SetDriverAction
    | OpenPaymentModalAction
    | ClosePaymentModalAction
    | UpdateOrderTotalsAction
    | OpenRefundModalAction
    | CloseRefundModalAction
    | BeginRefundAction
    | UpdateCreditBalanceAction
    | SelectDriverAction
    | ToggleEditModeAction
    | ClearCustomerAction
    | VoidReservationAction
    | CancelReservationAction
    | SetVoidReservationModalAction
    | SetCancelReservationModalAction
    | ClearDiscountAction
    | SetDiscountAction
    | SetInvalidDiscountAction
    | ToggleNewTransactionAction
    | AddProductToLineItemAction
    | SetCheckinAction
    | EditDriverAction
    | VipSyncInProgressAction