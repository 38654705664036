import { Controller } from "stimulus"
const axios = require('axios')

function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}

export default class extends Controller {
  static targets = [ "billing" , "discount"]

  connect() {
    console.log("reservation controller connected")
  }

  async discountChanged(event) {
    /*let client = getClient()
    let r = await client.post(`/reservations/${this.data.get("id")}/apply_discount`)
    this.discountTarget.innerHTML = r.data*/
  }

  async lineItemChanged(event) {
    let client = getClient()
    let r = await client.post(`/reservations/${this.data.get("id")}/billing_info`)
    this.billingTarget.innerHTML = r.data
  }  

}