import React, { useReducer, useContext, createContext, useState, useEffect,useRef } from "react"
import { useReservationContext } from './Provider'
import client from "../../src/client"
import { formatDate, useDebounce, formatCurrency } from '../../src/utils'
import CurrencyField from '../CurrencyField'
import Button from '../Button'
import { ActionType } from "../types/actions"
import {PayeableType} from './PaymentForm'


function buildRefundUrl(payeableType:PayeableType,payeableId:number,paymentId:number) {
    let url;

    if(payeableType == PayeableType.Reservation) {
        url = `/api/v1/reservations/${payeableId}/refund/${paymentId}`
    }

    if(payeableType == PayeableType.Transaction) {
        url = `/api/v1/transactions/${payeableId}/refund/${paymentId}`
    }

    if(!url) {
        throw "Payeable type not supported yet"
    }

    return url
}

export default function RefundForm({onSuccess}) {

    const { state, dispatch,reloadReservation,reloadCheckin } = useReservationContext()
    const [busy,setBusy] = useState(false)
    const [refundAmount,setRefundAmount] = useState(Math.abs(state.payment.amount))
    const [notify,setNotify] = useState(true)

    useEffect(()=>{
        if(refundAmount > state.payment.amount) {
            setRefundAmount(state.payment.amount)
        }
        if(refundAmount < 1) {
            setRefundAmount(1)
        }
    },[refundAmount])

    async function handleRefundClick() {

        setBusy(true)
        let refund = {
            amount: refundAmount,
            paymentId: state.payment.id,
            notifyCustomer: notify
        }
        console.log("refund=", refund)
        let url = buildRefundUrl(state.payment.payeableType,state.payment.payeableId,state.payment.id)
        console.log("refund url=", url)

        let r = await client.post(url,refund)
        
        await reloadReservation()
        await reloadCheckin()

        dispatch({type:ActionType.CloseRefundModal})
        
        if(onSuccess) {
            onSuccess()
        }

    }   

    return (
        <div className="w-128">
            
            <div className="grid grid-cols-2">
                <div  className="form-group">
                    <label>Payment For</label>
                    {state.payment.payeableType} #{state.payment.payeableId}
                </div>
                <div className="form-group">
                    <label>Payment Type</label>
                    {state.payment.paymentType}
                </div>
            </div>

            <div className="form-group">
                <label>Refund Amount</label>
                <CurrencyField value={refundAmount} onChange={(e)=>setRefundAmount(e.target.value)} />
                <div className="text-gray-300 my-2">{formatCurrency(state.payment.amount)} available for refund</div>
            </div>
            <div className="form-group">
                <div className="flex items-center cursor-pointer" onClick={()=>setNotify(!notify)}>
                    <input className="mr-3" checked={notify}  type="checkbox"/>
                    <div>Send a notification to the customer?</div>
                </div>
            </div>            
            <div>
                <Button busy={busy} onClick={handleRefundClick}>Refund {formatCurrency(refundAmount)}</Button>
            </div>            
        </div>
    )
}