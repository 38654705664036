import React, {Fragment, useContext,useState,useEffect,useRef } from 'react';
const classNames = require("classnames")


function ButtonContainer({className,children,submit,onClick,href}) {
    if(submit) {
        return (
            <button className={className} type="submit">
                {children}
            </button>
        )
    }
    if(href) {
        return (
            <a href={href} className={className}>{children}</a>
        )
    }
    return (
        <a onClick={onClick} className={className}>{children}</a>
    )
}



export default function Button({onClick,danger,busy,secondary,disabled,lg,sm,xs,href,children,block,icon,submit}) {

    function handleClick(e) {
        if(!disabled && !busy && !href) {
            e.preventDefault()
            onClick()
        }
    }

    let classList = classNames("text-white cursor-pointer text-center rounded" ,{
        "opacity-50 cursor-wait": (busy || disabled),
        "text-lg p-3": lg,
        "p-2 text-sm": sm,
        "text-sm p-1": xs,
        "p-2": (!lg && !sm && !xs),
        "block mb-1 w-full": block,
        "bg-primary-600 hover:bg-primary-800 text-black": (!danger && !secondary),
        "bg-red-600 hover:bg-red-800": danger,
        "bg-blue-100 hover:bg-blue-900": secondary
    })


    return (
        <ButtonContainer submit={submit} href={href} className={classList} onClick={handleClick}>
            {busy && <span>
                <i className="fas fa-spinner fa-spin mr-3"></i>
            </span>}
            {children}
        </ButtonContainer>
    )
}