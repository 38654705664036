import { PayeableType } from "../PaymentForm"
import {Discount, Driver, Payment, Transaction} from "../../types"

export function mapTrackDateProducts( track_date_product ) {

    return {
        ...track_date_product,
        price: parseFloat(track_date_product.price),
        product: {
            ...track_date_product.product,
            pricedByVehicle: track_date_product.product.priced_by_vehicle,
            pricedByTrackDate:  track_date_product.product.priced_by_track_date,
            price: parseFloat(track_date_product.product.price)
        }
    }
}

export function mapVehicleProduct(vehicle_product) {

    return {
        ...vehicle_product,
        price: parseFloat(vehicle_product.price),
        product: {
            ...vehicle_product.product,
            pricedByVehicle: vehicle_product.product.priced_by_vehicle,
            pricedByTrackDate:  vehicle_product.product.priced_by_track_date,
            price: parseFloat(vehicle_product.product.price),
        }
    }
}

export function mapTrackDateVehicle(track_date_vehicle) {


    return {
        ...track_date_vehicle,
        price: parseFloat(track_date_vehicle.price),
        vehicle: {
            ...track_date_vehicle.vehicle,
            price: parseFloat(track_date_vehicle.vehicle.price),
            vehicleProducts: track_date_vehicle.vehicle.vehicle_products?.map(mapVehicleProduct)
        }
    }
}

export function mapTrackDate(track_date) {

    return {
        ...track_date,
        trackDateVehicles: track_date.track_date_vehicles?.map(mapTrackDateVehicle) ,
        trackDateGroup: {
            ...track_date.track_date_group,
            eventOrganizer: track_date?.track_date_group?.event_organizer,
            registrationFee: track_date?.track_date_group?.registration_fee,
        },
        trackDateProducts: track_date.track_date_products?.map(mapTrackDateProducts)
    }
}

export function mapPayment(payment):Payment {


    let payeableType;

    if(payment.payeable_type == "Reservation") {
        payeableType = PayeableType.Reservation
    }

    if(payment.payeable_type == "Checkin") {
        payeableType = PayeableType.Checkout
    }

    if(payment.payeable_type == "Transaction") {
        payeableType = PayeableType.Transaction
    }

    return {
        id: payment.id,
        amount: parseFloat(payment.amount),
        refundAmount: parseFloat(payment.refund_amount),
        paymentType: payment.payment_type,
        cardType: payment.card_type,
        lastFour: payment.card_last4,
        expiresMonth: payment.card_exp_month,
        expiresYear: payment.card_exp_year,
        refundedAt: payment.refunded_at,
        createdAt: payment.created_at,
        quickbooksId: payment.quickbooks_id,
        payeableId: payment.payeable_id,
        payeableType: payeableType
    }
}

export function mapDriver(driver):Driver {

    if(!driver) {
        return null
    }

    return {
        id: driver.id,
        firstName: driver.first_name,
        lastName: driver.last_name,
        email: driver.email,
        phone: driver.phone,
        emergencyContact: driver.emergency_name,
        emergencyPhone: driver.emergency_phone,
        experienceLevel: driver.experience_level,
        licenseNumber: driver.license_number,
        dob: driver.dob
    }
}

export function mapTransaction(transaction):Transaction {
    if(!transaction) {
        console.log("transaction is null")
        return null
    }


    return {
        id: transaction.id,
        totalPrice: parseFloat( transaction.total_price ),
        balanceDue: parseFloat( transaction.balance_due ),
        createdAt: transaction.created_at,
        draft: transaction.draft,
        payments: transaction.payments.map(mapPayment),
        lineItems: transaction.line_items.map((lineItem)=>{

            return {
                id: lineItem.id,
                unitPrice: lineItem.unit_price,
                quantity: parseInt( lineItem.quantity ),
                subTotal: lineItem.sub_total,
                trackDateProduct: {
                    ...lineItem.track_date_product
                }
            }
        })
    }
}

export function mapDiscount(discount):Discount {
    if(!discount) {
        return null
    }

    return {
        id: discount.id,
        fixed: discount.discount_type == "fixed",
        discountAmount: discount.amount,
        percentageAmount: discount.percentage_amount,
        percentage: discount.discount_type == "percentage",
        priceRule: discount.price_rule,
        code: discount.code,
        vehicle: {
            ...discount.vehicle
        },
        product: {
            ...discount.product
        },
        trackDateGroup: {
            ...discount.track_date_group,
            trackDates: (discount.track_date_group) ? discount.track_date_group?.track_dates?.map(mapTrackDate) : null
        },
        expiresAt: discount.expires_at,
        startsAt: discount.starts_at
    }
}

