import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import Errors from './Errors'
import { useForm } from "react-hook-form";
import client from "../../src/client"

export default function CustomerForm({onSave}) {

    const { state, context } = useReservationContext()
    const { register, handleSubmit, watch, errors, reset, setValue } = useForm();
    const [serverErrors,setErrors] = useState()
    
    const onSubmit = async (data) => {

        try {
            let r = await client.post(`/api/v1/customers`,{customer: data})

            if(onSave) {
                onSave(r.data)
            }
    
        } catch(e) {
            if(e.isAxiosError) {
                console.error(e.response.data.errors)
                setErrors(e.response.data.errors)
            } else {
                console.error(e)
            }
        }

    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Errors errors={serverErrors}/>

            <div>
                <div className="sm:grid sm:grid-cols-2 mb-5 gap-2">
                    <div className="form-group">
                        <label>First Name</label>
                        <input name="first_name" ref={register} className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Last Name</label>
                        <input name="last_name" ref={register} className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input name="email" ref={register} className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>Phone Number</label>
                        <input name="phone" ref={register} className="form-control" placeholder="(888) 888-8888" type="tel" />
                    </div>


                </div>


                <div className="form-group flex justify-between">
                    <button className="btn btn-primary block w-full cursor-pointer" type="submit">Save</button>
                </div>                

            </div>
        </form>
    )
}