import IMask from 'imask'
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    //console.log(this.element)
    let mask = IMask(
        this.element, {
        mask: '(000) 000-0000'
    });    
  }
}
