import React, { useEffect, useState } from "react";
import { formatCurrency } from "./TopCars";


export default function TopCustomers({ bookings }) {

    const [data,setData] = useState([])
    const [total,setTotal] = useState(0)

    useEffect(()=>{

        if(!bookings) {
            return null
        }

        let total = 0
        let topCustomers = Object.values(bookings.reduce((acc,b)=>{
        
            if(!acc[b.customer.id]) {
                acc[b.customer.id] = {
                    customer: b.customer,
                    gross: 0
                }
            }
            acc[b.customer.id].gross += parseFloat( b.total_price )
            total += 1
    

            return acc
        },{})).slice(0,10)

        setData(topCustomers)
    

    },[bookings])



    return (
        <div >
            <div className="text-xl font-bold mb-5">Top Ten Customers</div>
            <div>
                <div className="grid grid-cols-5 gap-1 bg-gray-900 text-sm">
                    <div className="col-span-4">Customer Name</div>
                    <div className="text-right">Grand Total</div>
                </div>                
                {data.map((item) => {
                    return (
                        <div key={item.customer.id} className="grid grid-cols-5 text-sm border-b border-gray-800">
                            <div className="col-span-4">{item.customer.name}</div>
                            <div className="text-right">{formatCurrency(item.gross)}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}