import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["total"]

  connect() {
    console.log("connected to fuel controller", this.data.get("price"))
  }

  quantityChanged(e) {
    let quantity = e.currentTarget.value
    let total = quantity * this.data.get("price")

    this.totalTarget.value = total
  }

}
