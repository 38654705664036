import { Controller } from "stimulus"
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}


export default class extends Controller {
  static targets = [ "link" ]

  connect() {
    
  }

  async add(event) {

    let html = this.linkTarget.innerHTML
    let client = getClient()

    this.linkTarget.innerHTML = "Adding..."

    const url = `${window.location.pathname}`;
    let r = await client.post(this.data.get("url"))
    //Turbolinks.clearCache();
    Turbolinks.visit(url);    
  }

  async remove(event) {
    this.linkTarget.innerHTML = "Removing..."
    const url = `${window.location.pathname}`;
    let client = getClient()
    let r = await client.post(this.data.get("url"))
    //Turbolinks.clearCache();
    Turbolinks.visit(url);    
  }  
}
