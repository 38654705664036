import React, { useState } from "react"
import client from "../../src/client"
import { formatCurrency, formatDate } from '../../src/utils'
import Button from '../Button'
import { ActionType } from "../types/actions"
import { useReservationContext } from './Provider'

function buildPaymentUrl(payeableType:string,payeableId:number) {
    let url;

    if(payeableType == "Reservation") {
        url = `/api/v1/reservations/${payeableId}/payment`
    }

    if(payeableType == "Transaction") {
        url = `/api/v1/transactions/${payeableId}/payment`
    }


    return url
}


export default function PaymentRow({ payment }) {

    const { state, dispatch } = useReservationContext()
    const [busy,setBusy] = useState(false)

    console.log("PaymentRow.payment=", payment)

    async function sendPaymentLink() {
        let url = buildPaymentUrl(payment.payeableType,payment.payeableId)
        console.log("POST", url)
        try {
            setBusy(true)
            await client.post(url,{
                payment_type: payment.paymentType,
                amount: payment.balanceDue                
            })
        } catch(e) {
            console.error(e)
        }
        setBusy(false)
    }

    return (
        <div className="grid grid-cols-4 py-3">
            <div>
                <label>Payment Type</label>
                {payment.paymentType == 'link' &&
                    <>
                        <div className="mb-3">Payment Link Sent</div>
                        <div>
                            <Button busy={busy} onClick={() => {
                                sendPaymentLink()
                            }} xs>Re-Send Payment Link</Button>
                        </div>
                    </>
                }

                {payment.paymentType == 'credit_card' &&
                    <>
                        {payment.cardType} ending in {payment.lastFour}
                    </>
                }
                {payment.paymentType == 'cash' &&
                    <>
                        Cash
                    </>
                }
                {payment.paymentType == 'balance' &&
                    <>
                        Credit Balance
                    </>
                }
            </div>
            <div>
                <label>Payment Amount</label>
                <div>{formatCurrency(payment.amount)}</div>
            </div>
            <div>
                <label>Payment Date</label>
                <div>{formatDate(payment.createdAt)}</div>
            </div>

            <div>
                {!payment.refundedAt && <>
                    <Button onClick={() => {
                        dispatch({
                            type: ActionType.BeginRefund,
                            payment: payment
                        })
                    }}>Refund</Button>
                </>}

                {payment.refundedAt &&
                    <>
                        <div>
                            <label>Refund Issued</label>
                            <div>{formatDate(payment.refundedAt)}</div>
                            <div className="text-green-400 font-bold">-{formatCurrency(payment.refundAmount)}</div>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}