import { Controller } from "stimulus"
const debounce = require('lodash/debounce');
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}


export default class extends Controller {
  static targets = [ "input","results","hidden" ]

  initialize() {
    this.onChange = debounce(this.onChange, 400)
    this.inputTarget.focus()
  }  

  connect() {
  
    
    //this.moveCursorToEnd(this.inputTarget)
  }

  clear() {
    this.inputTarget.value = ''
    this.resultsTarget.innerHTML = null
  }

  onChange(e) {
    let q = this.inputTarget.value
    if(q == '') {
        this.clear()
    } else {
        this.search(q)
    }
  }

  async search(q) {
    let client = getClient()
    let r = await client.get(`/customers/search?q=${q}`)
    this.resultsTarget.innerHTML = r.data          
  }

  select(e) {
    Turbolinks.visit(`${location.pathname}?customer_id=${e.target.dataset.id}`)
  }

}
