import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"


export default class extends Controller {
  static targets = [ "file","form","progress","label","input" ,"preview"]

  connect() {
    addEventListener("direct-upload:initialize", event => {
      
    })

    addEventListener("direct-upload:start", event => {
       
    })

    addEventListener("direct-upload:end", event => {
       
    })
  }

  click(event) {
    this.fileTarget.click()
  }

  loadPreview(file) {
    const reader = new FileReader();
    reader.onload = (e) =>{
        console.log("image loaded from filereader=", e)
        this.previewTarget.src = e.target.result
    }
    reader.readAsDataURL(file);      
  }

  fileChanged(event) {
      
      const file = this.fileTarget.files[0]
      const url = this.fileTarget.dataset.directUploadUrl;

      const upload = new DirectUpload(file, url, this);

      this.labelTarget.classList.toggle('hidden')
      this.progressTarget.classList.toggle('hidden')

      upload.create((error, blob) => {
          console.log(error,blob)
          if(!error) {
              this.loadPreview(file)
              this.inputTarget.value = blob.signed_id
              this.labelTarget.classList.toggle('hidden')
              this.progressTarget.classList.toggle('hidden')        
          }
      })



      //this.formTarget.submit()
  }

}