import React, { useReducer, useContext, createContext, useState, useEffect,useRef } from "react"
import { useReservationContext } from './Provider'
import client from "../../src/client"
import { formatDate, useDebounce, formatCurrency } from '../../src/utils'
import CurrencyField from '../CurrencyField'
import Button from '../Button'
import { ActionType } from "../types/actions"
const classNames = require("classnames")

export enum PayeableType {
    Reservation = "Reservation",
    Checkout = "Checkout",
    Transaction = "Transaction"
}

interface PaymentFormProps {
    onSuccess: Function
    allowMultiple: boolean,
    initialAmount: number
    payeableType: PayeableType
    payeableId: number
}

function buildPaymentUrl(payeableType:PayeableType,payeableId:number) {
    let url;

    if(payeableType == PayeableType.Reservation) {
        url = `/api/v1/reservations/${payeableId}/payment`
    }

    if(payeableType == PayeableType.Transaction) {
        url = `/api/v1/transactions/${payeableId}/payment`
    }


    return url
}

export default function PaymentForm({onSuccess,initialAmount,payeableType,payeableId,allowMultiple=false}:PaymentFormProps) {

    if(!initialAmount) {
        initialAmount = 1
    }

    const { state, dispatch, reloadReservation } = useReservationContext()
    const [stripe,setStripe] = useState()
    const [card,setCard] = useState()
    const [amount,setAmount] = useState(initialAmount)
    const [paymentIntent,setPaymentIntent] = useState()
    const [paymentType,setPaymentType] = useState<String>("credit_card")
    const [busy,setBusy] = useState(false)
    const [paymentLinkMessage,setPaymentLinkMessage] = useState('Thank you for booking  your track day event:')
    const [name,setName] = useState('')

    let ref = useRef()

    async function refreshPaymentIntent() {
        try {

            console.log("refresh payment intent amount=", amount)
            let r = await client.post("/checkins/intent",{
                amount: amount
            })
            setPaymentIntent(r.data)

            
        } catch(e) {

            console.error(e)

        }
    }

    useEffect(()=>{

        console.log("ref=", ref)

        if(ref.current) {
            let stripeMeta = document.querySelector('meta[name="stripe-key"]')
            let stripeKey = stripeMeta.getAttribute("content")
            setStripe(window.Stripe(stripeKey))
            refreshPaymentIntent()
        }

    },[ref])



    useEffect(()=>{

        if(stripe) {
            let elements  = stripe.elements()
    
            let card = elements.create("card")
            card.mount(ref.current)
            card.addEventListener("change", (e)=>{
                console.log("card listener change=", e)
            })        
            setCard(card)
        }

    },[stripe])


    async function handlePaymentClick() {

        setBusy(true)
        let url = buildPaymentUrl(payeableType,payeableId)

        let r = await client.post( url ,{
            payment_type: paymentType,
            message: paymentLinkMessage,
            amount: amount
        })
        console.log("data=",r.data)

        /*await reloadReservation()
        dispatch({type: ActionType.ClosePaymentModal})*/

        await onSuccess()

    }

    function handleCreditCardPaymentClick() {

        setBusy(true)
        let url = buildPaymentUrl(payeableType,payeableId)

        let data = {
            payment_method: {
              card: card,
              billing_details: {
                name: name
              }
            }
        }        

        stripe.confirmCardPayment(paymentIntent.client_secret,data).then(async (result) => {
            let r = await client.post( url ,{
                payment_type: paymentType,
                payment_intent_id: paymentIntent.id,
                amount: amount
            })
            console.log("data=",r.data)
            /*await reloadReservation()
            dispatch({type: ActionType.ClosePaymentModal})*/

            await onSuccess()
        })
    }


    function tabClassNames(isActive) {
        return classNames("border-b-2 p-2 uppercase text-sm cursor-pointer hover:border-primary-600",{
            "border-transparent" : !isActive,
            "border-white": isActive
        })
    }

    return (
        <div className="max-w-2xl" style={{height:400}}>

            <div className="border-b border-gray-10 mb-5 grid grid-cols-4 gap-x-5 text-center">
                <div onClick={()=>setPaymentType("credit_card")} className={tabClassNames(paymentType == "credit_card")}>
                    Credit Card
                </div>
                <div onClick={()=>setPaymentType("cash")} className={tabClassNames(paymentType == "cash")}>
                    Cash
                </div>
                
                <div onClick={()=>setPaymentType("balance")} className={tabClassNames(paymentType == "balance")}>
                    Credit Balance
                </div>

                <div onClick={()=>setPaymentType("link")} className={tabClassNames(paymentType == "link")}>
                    Payment Link
                </div>
            </div>


            {(!paymentType || paymentType == 'credit_card') &&
                <div>

                    <div className="form-group">
                        <label>Amount</label>
                        <div className="flex items-center">

                            {allowMultiple ? 
                                <CurrencyField value={amount} onChange={(e)=>setAmount(e.target.value)} onBlur={refreshPaymentIntent} /> 
                                : 
                                <span className="text-2xl font-bold">{formatCurrency(amount)}</span>
                            }
                            

                            <div className="p-2 hidden">
                                {paymentIntent &&
                                    <>
                                        <span className="text-xs text-gray-300">{formatCurrency((paymentIntent.amount/100).toString())} will be charged via Stripe</span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Credit or debit card</label>
                        <div ref={ref} data-target="stripe.card" className="form-control"></div>
                        <div data-target="stripe.error" role="alert" className="text-red-600 text-sm mt-2"></div>
                    </div>

                    <div className="form-group">
                        <label>Name on card</label>
                        <input value={name} onChange={(e)=>setName(e.target.value)} placeholder="Full Name" className="form-control" />
                    </div>

                    {!paymentIntent &&
                        <div className="my-5 bg-red-600 text-white p-3 text-sm text-center">
                            There was an error communicating with Stripe
                        </div>
                    }

                    {paymentIntent && <div>
                        <Button busy={busy} onClick={handleCreditCardPaymentClick}>Charge {formatCurrency(amount)}</Button>
                    </div>}
                                        
                </div>
            }


            {paymentType == 'cash' &&
                <div>
                    <div className="form-group">
                        <label>Amount</label>
                        {allowMultiple ? 
                                <CurrencyField value={amount} onChange={(e)=>setAmount(e.target.value)} onBlur={refreshPaymentIntent} /> 
                                : 
                                <span className="text-2xl font-bold">{formatCurrency(amount)}</span>
                        }
                    </div>

                    <div>
                        <Button busy={busy} onClick={handlePaymentClick}>Charge {formatCurrency(amount)}</Button>
                    </div>

                </div>
            }


            {(paymentType == 'balance' && (state.creditBalance)) &&
                <div>
                    <div className="form-group">
                        <label>Credit Balance</label>
                        {formatCurrency(state.creditBalance)}
                    </div>

                    <div className="form-group">
                        <label>Amount</label>
                        {allowMultiple ? 
                                <CurrencyField value={amount} onChange={(e)=>setAmount(e.target.value)} onBlur={refreshPaymentIntent} /> 
                                : 
                                <span className="text-2xl font-bold">{formatCurrency(amount)}</span>
                        }
                    </div>

                    <div>
                        <Button busy={busy} onClick={handlePaymentClick}>Charge {formatCurrency(amount)}</Button>
                    </div>

                </div>
            }


            {paymentType == 'link' &&
                <div>
                    <div className="form-group">
                        <label>Message</label>
                        <textarea value={paymentLinkMessage} onChange={(e)=>setPaymentLinkMessage(e.target.value)} className="form-control"></textarea>
                    </div>

                    <div>
                        <Button busy={busy} onClick={handlePaymentClick}>Send Payment Link</Button>
                    </div>

                </div>
            }

        </div>
    )
}
