import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "el","label" ]

  connect() {
    

    let start = moment(this.element.dataset["startDate"])
    let end = moment(this.element.dataset["endDate"])

    //let start = moment().subtract(29, 'days');
    //let end = moment();

    $(this.elTarget).daterangepicker({
      startDate: start,
      endDate: end,
      autoApply: true 
    }, (start,end)=>{
      this.onChange(start,end)
    });

    //this.onChange(start, end);    
    this.labelTarget.innerText = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')


  }

  onChange(start,end) {
    //this.labelTarget.innerText = start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY')
    Turbolinks.clearCache()
    Turbolinks.visit(`?start_date=${start.format("YYYY-MM-DD")}&end_date=${end.format("YYYY-MM-DD")}`)
  }

}
