import { Controller } from "stimulus"
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}


export default class extends Controller {
  static targets = [ "input","submit" ]

  connect() {

    this.changed()

  }

  changed(e) {
      let valid = true
      this.inputTargets.forEach((x)=>{

            console.log(x.value)

          if (x.value == '') {
              valid = false
          }
      })

      this.valid = valid

      if(valid == true) {
        this.submitTarget.classList.remove("opacity-50")
        this.submitTarget.classList.remove("cursor-not-allowed")
      } else {
        this.submitTarget.classList.add("opacity-50")
        this.submitTarget.classList.add("cursor-not-allowed")
      }
  }




  validate(e) {
      if(this.valid == false) {
        e.preventDefault()
        return false
  
      }
  }


}
