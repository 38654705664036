import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { startOfMonth, endOfMonth, add, eachMonthOfInterval, eachDayOfInterval, format, startOfWeek,endOfWeek, isSameMonth, sub, formatISO } from 'date-fns'
import client from '../src/client'
import { useReservationContext } from './admin/Provider'
const classNames = require("classnames")

function hexToRGB( hex , alpha) {
    return `rgba(${hex.replaceAll("#","").match(/.{1,2}/g).map(x=>parseInt(x,16)).concat(alpha ?? 0.5).join(",")})`
}

export default function CalendarPicker({onPick}) {

    const [date,setDate] = useState(new Date())
    const [months,setMonths] = useState([])
    const [marks,setMarks] = useState({})
    const [tracks,setTracks] = useState()
    const [datesInCart,setDatesInCart] = useState()
    const { state, dispatch } = useReservationContext()

    async function loadCalendarData() {
        const startDate = formatISO(startOfMonth(date),{ representation: 'date' })
        const endDate = formatISO(endOfMonth(add(date, { months: 5 })),{ representation: 'date' })

        try {
            let r = await client.get(`/api/v1/calendar/${startDate}/${endDate}`)
            setMarks(r.data)

            let keys = Object.keys(r.data)
            let updatedTracks = []
            let trackIds = {}
            for(let key of keys) {
                for(let trackDate of r.data[key].tracks) {
                    if(!trackIds[trackDate.track.id]) {
                        updatedTracks.push(trackDate.track)
                        trackIds[trackDate.track.id] = true
                    }
                }
            }
            setTracks(updatedTracks)

        } catch(e) {
            console.error(e)
        }

    }

    useEffect(()=>{

        const startDate = startOfMonth(date)
        const endDate = endOfMonth((add(startDate, { months: 5 })))
    
        let updatedMonths = eachMonthOfInterval({ start: startDate, end: endDate }).map(x=>({date: x,days:[]}))
    
        for (let month of updatedMonths) {
            let som = startOfWeek(month.date,{weekStartsOn:1})
            let eom = endOfWeek(endOfMonth(month.date),{weekStartsOn:1})
            let days = eachDayOfInterval({
                start: som,
                end: eom
            })
            month.days = days
    
        }
        
        setMonths(updatedMonths)

        loadCalendarData()

    },[date])

    useEffect(()=>{

        if(state && state.lineItems) {

            let dates = state.lineItems.reduce((acc,lineItem)=>{

                acc[lineItem.trackDate.date] = true

                return acc
            },{})

            console.log("dates with selections=", dates)
            setDatesInCart(dates)

        }

    },[state])


    function next() {
        setDate(add(date,{months:1}))
    }

    function previous() {
        setDate(sub(date,{months:1}))
    }  

    function handleDayClick(mark) {
        if(mark && onPick) {
            onPick(mark)
        }
    }


    return (
        <div>
            <div className="sm:flex flex-row justify-center mb-5">
                {tracks && tracks.map((track)=>{
                    let backgroundColor = hexToRGB(track.color,0.5)
                    return (
                        <div key={track.id} style={{backgroundColor: backgroundColor,borderColor: track.color}} className="mx-2 text-sm rounded text-white font-bold p-1 mb-2 border-4 ">{track.name}</div>
                    )
                })}
            </div>
            <div className="overflow-y-scroll w-full py-3">
                <div className="grid grid-cols-6 gap-x-10" style={{width: 1600}}>
                    {months.map((month,index) => {
                        return (
                            <div key={index}>
                                <div className="flex flex-row relative justify-between items-center text-white p-2 text-center w-auto">
                                    <div>
                                        {index == 0 &&
                                            <div onClick={previous} className="text-white cursor-pointer hover:text-spms-green">
                                                <i className="fas fa-arrow-left"></i>
                                            </div>
                                        }
                                    </div>
                                    <div className="text-lg text-center font-bold">{format(month.date, "LLLL")}</div>
                                    <div>
                                        {((index+1) == months.length) &&
                                            <div onClick={next} className="text-white cursor-pointer hover:text-spms-green">
                                                <i className="fas fa-arrow-right"></i>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="grid grid-cols-7 gap-2">
                                    <div className="w-12 h-6 text-xs">Mon</div>
                                    <div className="w-12 h-6 text-xs">Tue</div>
                                    <div className="w-12 h-6 text-xs">Wed</div>
                                    <div className="w-12 h-6 text-xs">Thu</div>
                                    <div className="w-12 h-6 text-xs">Fri</div>
                                    <div className="w-12 h-6 text-xs">Sat</div>
                                    <div className="w-12 h-6 text-xs">Sun</div>
                                    {month.days.map((day,index)=>{

                                        const isVisible = isSameMonth(month.date,day)
                                        let dateSlug = formatISO(day,{representation: "date"})
                                        let mark = marks[dateSlug]
                                        let dateEnabled = mark != null
                                        let hasDateInCart = (datesInCart && datesInCart[dateSlug])

                                        let classList = classNames("w-12 text-center relative",{
                                            "text-gray-100 cursor-pointer": mark,
                                            "text-gray-600": !mark
                                        })


                                        if(!isVisible) {
                                            return (
                                                <div key={index}></div>
                                            )
                                        }


                                        return (
                                            <div key={index} className={classList} onClick={()=>{
                                                    handleDayClick(day,mark)
                                                }}>

                                                {hasDateInCart && <div className="absolute right-0 top-0 text-xs">
                                                    <div className="flex items-center justify-center text-primary-600">
                                                        <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                                                    </div>
                                                </div>}

                                                <div>{format(day,"d")}</div>
                                                {mark && mark.tracks.map((trackDate,index)=>{

                                                    let keyClassList = classNames("",{
                                                        "rounded-l ml-1": (mark && trackDate.starting_day),
                                                        "rounded-r mr-1": (mark && trackDate.ending_day)
                                                    })

                                                    return (
                                                        <div key={index} className={keyClassList} style={{height:6,marginBottom:4,backgroundColor:trackDate.track.color}}></div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
