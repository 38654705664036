import React, { useEffect, useState } from "react"
import client from "../../src/client"
import { formatCurrency, formatDate, formatDateLong, formatDateShort, getVehicleImageUrl } from '../../src/utils'
import Button from '../Button'
import CalendarPicker from '../Calendar'
import { Product, TrackDate, TrackDateVehicle, Vehicle, VehicleProduct } from "../types"
import { ActionType } from "../types/actions"
import CancelForm from "./CancelForm"
import Customer from './Customer'
import CustomerForm from './CustomerForm'
import DriverForm from './DriverForm'
import LineItem from './LineItem'
import { mapDriver } from "./mappers"
import Modal from './Modal'
import PaymentForm, { PayeableType } from "./PaymentForm"
import PaymentRow from "./PaymentRow"
import { useReservationContext } from './Provider'
import RefundForm from "./RefundForm"
import TrackDateVehicles from './TrackDateVehicles'
import TransactionForm from "./TransactionForm"
import VoidForm from "./VoidForm"
const classNames = require("classnames")

function OrderDetails() {

    const { state, dispatch } = useReservationContext()

    return (
        <div>
            {state.lineItems.map((lineItem, index) => {
                return (
                    <LineItem key={index} index={index} lineItem={lineItem} />
                )
            })}
        </div>
    )
}


function Panel({ children }) {
    return (
        <div className="p-5 mr-5 mb-5 border-2 border-gray-800 bg-gray-900 rounded-lg shadow-xl relative">
            {children}
        </div>
    )
}

function mapVehicleProduct(vehicleProductData: any) {

    let product: Product = {
        ...vehicleProductData.product,
        pricedByVehicle: vehicleProductData.product.priced_by_vehicle,
        pricedByTrackDate: vehicleProductData.product.priced_by_track_date,
        price: parseFloat(vehicleProductData.product.price)
    }

    let vehicleProduct: VehicleProduct = {
        ...vehicleProductData,
        price: parseFloat(vehicleProductData.price),
        product: product
    }

    return vehicleProduct

}

function Badge({ children, color }) {


    return (
        <span className={`p-1 px-2 rounded-md text-xs uppercase mr-2 ${color}`}>
            {children}
        </span>
    )
}

export default function Reservation({ }) {

    const { state, dispatch,vipSync,vipMarkAsPaid, save, makePayment, reloadReservation, applyDiscount, createAuthorizationRequest, clearAuthorizationRequest, updateDriver } = useReservationContext()
    const [trackDates, setTrackDates] = useState<TrackDate[]>()
    const [showCalendar, setShowCalendar] = useState(false)
    const [showCustomerForm, setShowCustomerForm] = useState(false)
    const [notify, setNotify] = useState(true)
    const [busy, setBusy] = useState(false)
    const [discountCode, setDiscountCode] = useState('')
    const [emails, setEmails] = useState([])
    const [authorizations, setAuthorizations] = useState([])
    const [showAuthorization, setShowAuthorization] = useState(false)


    async function loadTrackDate(date) {
        let r = await client.get(`/api/v1/track_dates/${date}`)
        setTrackDates(r.data.map((data) => {
            let trackDate: TrackDate = {
                ...data,
                trackDateGroup: {
                    ...data.track_date_group,
                    eventOrganizer: data.track_date_group.event_organizer,
                    registrationFee: parseFloat(data.track_date_group.registration_fee)
                },
                trackDateVehicles: data.track_date_vehicles.map((trackDateVehicleData) => {

                    let vehicle: Vehicle = {
                        ...trackDateVehicleData.vehicle,
                        vehicleProducts: trackDateVehicleData.vehicle.vehicle_products.map(mapVehicleProduct)
                    }

                    let trackDateVehicle: TrackDateVehicle = {
                        ...trackDateVehicleData,
                        price: parseFloat(trackDateVehicleData.price),
                        vehicle: vehicle
                    }
                    return trackDateVehicle
                }),
                trackDateProducts: data.track_date_products.map((trackDateProduct) => {
                    return {
                        ...trackDateProduct,
                        price: parseFloat(trackDateProduct.price),
                        product: {
                            ...trackDateProduct.product,
                            pricedByVehicle: trackDateProduct.product.priced_by_vehicle,
                            pricedByTrackDate: trackDateProduct.product.priced_by_track_date,
                            price: parseFloat(trackDateProduct.product.price)
                        }
                    }
                })
            }
            return trackDate
        }))
    }

    async function handlePick(day) {
        console.log("day=", day)
        await loadTrackDate(day)
        //toggleCalendarPicker()
    }

    async function handleApplyDiscount() {
        await applyDiscount(discountCode)
    }

    function handleVehiclePick(trackDate, trackDateVehicle) {

        console.log("trackDate=", trackDate)
        console.log("trackDateVehicle=", trackDateVehicle)

        dispatch({ type: ActionType.AddLineItem, trackDate: trackDate, trackDateVehicle: trackDateVehicle })
    }

    function handleCustomerPick(customer) {
        dispatch({ type: ActionType.SetCustomer, customer: customer })
    }

    function clearCustomer(customer) {
        dispatch({ type: ActionType.ClearCustomer })
    }

    function clearDiscount() {
        dispatch({ type: ActionType.ClearDiscount })
    }

    async function loadEmails() {
        let r = await client.get(`/api/v1/reservations/${state.id}/emails`)
        setEmails(r.data)
    }

    async function loadAuthorizations() {
        let r = await client.get(`/api/v1/reservations/${state.id}/authorizations`)
        setAuthorizations(r.data)
    }

    async function quickbooksSync() {
        setBusy(true)
        try {
            let r = await client.post(`/api/v1/reservations/${state.id}/quickbooks_sync`)
        } catch(e) {
            console.error(e)
        }

        await reloadReservation(state.id)
        setBusy(false)
    }

    useEffect(() => {
        if (state.id) {
            loadEmails()
            loadAuthorizations()
        }
    }, [state.id])

    useEffect(() => {

        if (state.customer && state.lineItems && state.lineItems.length > 0) {
            setShowAuthorization(true)
        } else {
            setShowAuthorization(false)
        }

    }, [state.customer, state.lineItems])

    function toggleCalendarPicker() {
        setShowCalendar(!showCalendar)
    }

    function togglePaymentModal() {

    }

    function toggleCustomerModal() {
        setShowCustomerForm(!showCustomerForm)
    }

    async function handleSave() {
        setBusy(true)
        await save(notify)
        setBusy(false)
    }

    function toggleCancel() {
        dispatch({ type: ActionType.SetCancelReservationModal, value: !state.showCancelModal })
    }

    function toggleVoid() {
        dispatch({ type: ActionType.SetVoidReservationModal, value: !state.showVoidModal })
    }

    async function handleSendAuthorization() {
        setBusy(true)
        await save(false)
        console.log("create an authorization request")
        await createAuthorizationRequest()
        await loadAuthorizations()
        await loadEmails()
        setBusy(false)
    }

    async function handleClearAuthorization() {
        await clearAuthorizationRequest()
        await loadAuthorizations()
    }

    async function handleSyncVip() {

        let confirmationMessage = `
        Are you sure?
        
        VIP sync will:
        - Push this reservation to Quickbooks
        
        Make sure any unsaved changes are saved before proceeding.
        `

        if(confirm(confirmationMessage)) {
            setBusy(true)
            await vipSync()
            setBusy(false)
        }

    }

    async function handleVipMarkAsPaid() {
        setBusy(true)
        await vipMarkAsPaid()
        setBusy(false)
    }

    async function viewEmail(email: any) {
        let r = await client.get(`/api/v1/reservations/${state.id}/emails/${email.id}`)

        console.log(r.data)

    }

    let badges = []


    if (state.initialReservation) {
        if (state.initialReservation) {

        }
    }


    return (
        <div>

            <div className="flex justify-between items-center mb-5">
                <div>
                    <a href="/reservations" >Back to Reservations</a>
                    <h1 className="h3 my-1">
                        {state.editable && <span>Edit </span>}
                        Reservation #{state.id}
                        {state.initialReservation && <span className="text-gray-400 text-base ml-2">Created {formatDate(state.initialReservation.createdAt)}</span>}
                    </h1>
                    <div>
                        {state.initialReservation &&
                            <div className="flex items-center">


                                <span className="mr-3 text-sm">
                                    <a target="new" href={`/reservations/${state.id}/print`}>
                                        <i className="fa fa-file-pdf mr-1"></i>
                                        Download PDF
                                    </a>
                                </span>

                                {state.initialReservation.state == "payment_received" &&
                                    <>
                                        <Badge color="bg-green-600">Payment Received</Badge>
                                    </>
                                }
                                {state.initialReservation.state == "payment_link_sent" &&
                                    <Badge color="bg-yellow-600">
                                        Payment Link Sent  {formatDateShort(state.initialReservation.paymentLinkSentAt)}
                                    </Badge>
                                }
                                {state.initialReservation.state == "cancelled" &&
                                    <Badge color="bg-red-600">Cancelled</Badge>
                                }
                                {state.initialReservation.state == "voided" &&
                                    <Badge color="bg-red-600">Void</Badge>
                                }
                                {state.initialReservation.state == "partial_payment_received" &&
                                    <Badge color="bg-indigo-600">Partial Payment Received</Badge>
                                }
                                {state.initialReservation.refundedAt &&
                                    <Badge color="bg-indigo-600">Refunded</Badge>
                                }

                                {state.initialReservation.state == "waiting_approval" &&
                                    <Badge color="bg-yellow-400">Waiting Approval</Badge>
                                }

                                {state.initialReservation.state == "draft" &&
                                    <Badge color="bg-blue-400">Draft</Badge>
                                }

                                {state.initialReservation.state == "reserved" &&
                                    <Badge color="bg-blue-800">Reserved</Badge>
                                }

                                {state.initialReservation?.vipSyncedAt != null &&
                                    <Badge color="bg-yellow-600">
                                        VIP Push
                                    </Badge>
                                }


                                {state.initialReservation.quickbooksId &&
                                    <Badge color="bg-green-600">
                                        Synced w/ QB 
                                        {/*state.initialReservation.quickbooksSyncAt && <>
                                            @ {formatDate(state.initialReservation.quickbooksSyncAt)}
                                        </>*/}
                                    </Badge>
                                }

                                {!state.initialReservation.quickbooksId &&
                                    <>
                                        {state.initialReservation.quickbooksFailedAt &&
                                            <Badge color="bg-red-400">
                                                QB Sync Error
                                            </Badge>
                                        }
                                        {!state.initialReservation.quickbooksFailedAt &&
                                            <Badge color="bg-yellow-400">
                                                Not Synced w/ QB
                                            </Badge>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                </div>

                <div className="ml-auto flex items-center">

                    {(state.customer != null && state.editable == false && state.initialReservation.vipSyncedAt == null) && <>
                        <a onClick={handleSyncVip} className="text-blue-600 mr-3 cursor-pointer p-1 px-2 rounded-md border border-blue-600">
                            VIP Sync
                            {state.vipSyncInProgress && <> In Progress</>}
                        </a>
                    </>}

                    {(state.initialReservation?.cancelledAt == null && state.initialReservation?.voidedAt == null) &&
                        <>
                            <a onClick={toggleCancel} className="text-red-600 mr-3 cursor-pointer p-1 px-2 rounded-md border border-red-600">Cancel</a>
                            <a onClick={toggleVoid} className="text-red-600 mr-3 cursor-pointer p-1 px-2 rounded-md border border-red-600">Void</a>
                        </>
                    }

                    {(!state.locked && (state.initialReservation?.cancelledAt == null && state.initialReservation?.voidedAt == null)) ?
                        <>
                            {(state.editable == false) ? <div onClick={() => {
                                dispatch({ type: ActionType.ToggleEditMode })
                            }} className="flex items-center cursor-pointer p-1 px-2 rounded-md border border-primary-600">
                                <a>Edit</a>
                            </div>
                                :
                                <div onClick={() => {
                                    dispatch({ type: ActionType.ToggleEditMode })
                                }} className="flex items-center cursor-pointer p-1 px-2 rounded-md border border-primary-600">
                                    <a>Done Editing</a>
                                </div>
                            }
                        </>
                        :
                        <>

                        </>
                    }

                </div>

            </div>



            {state.showCancelModal &&
                <Modal onClose={toggleCancel}>
                    <div className="p-5 border-2 border-red-600 rounded-lg">
                        <CancelForm onSave={async () => {
                            await reloadReservation()
                            toggleCancel()
                        }} />
                    </div>
                </Modal>
            }

            {state.showVoidModal &&
                <Modal onClose={toggleVoid}>
                    <div className="p-5 border-2 border-red-600 rounded-lg">
                        <VoidForm onSave={async () => {
                            await reloadReservation()
                            toggleVoid()
                        }} />
                    </div>
                </Modal>
            }



            {(showCalendar) &&
                <Modal wide onClose={toggleCalendarPicker}>
                    <div className="p-3 rounded-xl shadow-xl">
                        <CalendarPicker onPick={handlePick} />
                        <div className="my-10">
                            {trackDates &&
                                <TrackDateVehicles onPick={handleVehiclePick} trackDates={trackDates} />
                            }
                        </div>
                        <Button onClick={toggleCalendarPicker} block>Done</Button>
                    </div>
                </Modal>
            }

            {state.showPaymentModal &&
                <Modal onClose={() => dispatch({ type: ActionType.ClosePaymentModal })} >
                    <div className="p-5">
                        <PaymentForm
                            payeableType={PayeableType.Reservation}
                            payeableId={state.id}
                            initialAmount={state.balanceDue}
                            allowMultiple={true}
                            onSuccess={async () => {
                                await reloadReservation()
                                dispatch({ type: ActionType.ClosePaymentModal })
                            }} />
                    </div>
                </Modal>
            }

            {state.showRefundModal &&
                <Modal onClose={() => dispatch({ type: ActionType.CloseRefundModal })}>
                    <div className="p-5">
                        <RefundForm onSuccess={async () => {
                            await reloadReservation()
                            dispatch({ type: ActionType.CloseRefundModal })
                        }} />
                    </div>
                </Modal>
            }


            {state.showTransactionModel &&
                <Modal title="New Transaction" onClose={() => dispatch({ type: ActionType.ToggleNewTransaction })}>
                    <div className="p-5 overflow-scroll-y">
                        <TransactionForm onSave={async () => {
                            await reloadReservation()
                            dispatch({ type: ActionType.ToggleNewTransaction })
                        }} />
                    </div>
                </Modal>
            }

            {state.showDriverModal && <Modal title="Add Driver" onClose={() => {
                dispatch({ type: ActionType.CloseDriverModal })
            }}>

                <div className="p-5 flex">
                    <div className="flex items-center mr-2">
                        <div className="w-32">
                            <img src={getVehicleImageUrl(state.lineItems[state.lineItemIndex].trackDateVehicle)} />
                        </div>
                    </div>
                    <div>
                        <div>{state.lineItems[state.lineItemIndex].trackDateVehicle.vehicle.name}</div>
                        <div>{state.lineItems[state.lineItemIndex].trackDateVehicle.vehicle.display_name}</div>
                    </div>
                </div>

                <div className="p-5">
                    <DriverForm onSave={async (driver) => {
                        console.log("onSave.driver=",driver)
                        dispatch({ type: ActionType.SetDriver, driver: mapDriver(driver) })
                        dispatch({ type: ActionType.CloseDriverModal })
                        if(state.lineItemIndex >= 0) {
                            await updateDriver(driver.id, state.lineItemIndex)
                        } else {
                            console.error("No line item index")
                        }
                    }} />
                </div>
            </Modal>}

            {showCustomerForm && <Modal onClose={toggleCustomerModal} title="Create Customer">
                <div className="p-5">
                    <CustomerForm onSave={(data) => {
                        dispatch({ type: ActionType.SetCustomer, customer: data })
                        toggleCustomerModal()
                    }} />
                </div>
            </Modal>}


            {(state.initialReservation && state.initialReservation.state == "cancelled") && <div className="bg-red-600 text-sm text-white p-2 rounded-md my-10">
                <div>Cancelled on {formatDate(state.initialReservation.cancelledAt)}</div>
                <div>Reason: {state.initialReservation.cancelReason}</div>
            </div>}

            {(state.initialReservation && state.initialReservation.quickbooksFailedAt) &&
                <div className="bg-red-600 text-sm text-white p-2 rounded-md my-3 flex items-center">
                    <div>
                        {state.initialReservation.quickbooksFailedAt && <div>Quickbooks Sync Error @ {formatDateLong(state.initialReservation.quickbooksFailedAt)}</div>}
                        <div>{state.initialReservation.quickbooksErrorMessage}</div>
                    </div>
                    <div className="ml-auto w-32">
                        <Button danger busy={busy} block onClick={quickbooksSync}>Try Again</Button>
                    </div>
                </div>
            }

            {/*(state.initialReservation?.vipSyncedAt != null && state.initialReservation?.vipPaymentReceivedAt == null) &&
                <div className="bg-black border border-white text-sm text-white p-2 rounded-md my-3 flex items-center">
                    <div>
                        <strong>VIP Payment Due</strong><br/>
                        This reservation was pushed to Quickbooks as a VIP but has not been marked as paid in this system.
                    </div>
                    <div className="ml-auto w-32">
                        <Button busy={busy} block onClick={handleVipMarkAsPaid}>Mark as Paid</Button>
                    </div>
                </div>
            */}


            <div className="flex">


                <div className="w-8/12">

                    <Panel>
                        {(state.editable && !state.locked) && <div className="mb-5 flex items-center justify-center p-5 border-b border-gray-500">
                            <a onClick={toggleCalendarPicker} className="btn btn-primary">
                                <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" /></svg>
                                View Booking Calendar
                            </a>
                        </div>}


                        <OrderDetails />
                    </Panel>


                    <Panel>


                        <div className="grid grid-cols-3 mb-10">
                            <div>
                                <div className="uppercase text-sm">Total Price</div>
                                {state.discount ?
                                    <>
                                        <div className="line-through text-gray-300">
                                            {formatCurrency(state.orderTotalBeforeDiscounts)}
                                        </div>
                                        <div className="text-2xl font-bold text-green-600">{formatCurrency(state.orderTotal)}</div>
                                        <div className="text-sm">
                                            Discount {state.discount.code} applied (-{formatCurrency(state.discountTotal)})
                                        </div>
                                    </>
                                    :
                                    <div className="text-2xl font-bold">{formatCurrency(state.orderTotal)}</div>
                                }

                            </div>

                            <div>
                                <div className="uppercase text-sm">Total Payments</div>
                                <div className="text-2xl font-bold">{formatCurrency(state.paymentsTotal)}<br /></div>
                            </div>

                            <div>
                                <div className="uppercase text-sm">Total Due</div>
                                <div className="text-2xl font-bold">{formatCurrency(state.balanceDue)}</div>
                            </div>
                        </div>


                        <div className="mb-10">
                            {state.payments.map((payment) => {
                                return (
                                    <PaymentRow payment={payment} />
                                )
                            })}
                        </div>



                        <div className="mb-5 grid grid-cols-2 gap-x-1">
                            {state.balanceDue > 0 &&
                                <>
                                    <Button onClick={makePayment}>Accept Payment {formatCurrency(state.balanceDue)}</Button>
                                </>
                            }
                        </div>

                    </Panel>

                    {showAuthorization && <Panel>
                        <div>
                            <div className="font-bold font-xl mb-3">Reservation Authorization and Approval</div>
                            {(authorizations && authorizations.length > 0) &&
                                <div className="mb-5">
                                    {authorizations.map((authorization) => {

                                        let verified = (authorization.verified_at != null)

                                        return (
                                            <div>
                                                <div className="grid grid-cols-3 mb-5">
                                                    <div>
                                                        <div className="uppercase text-sm">Sent</div>
                                                        <div className="text-2xl font-bold">
                                                            {formatDateShort(authorization.sent_at)}
                                                        </div>
                                                    </div>
                                                    {verified &&
                                                        <>
                                                            <div>
                                                                <div className="uppercase text-sm">Verified</div>
                                                                <div className="text-2xl font-bold text-green-400">
                                                                    {formatDateLong(authorization.verified_at)}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="uppercase text-sm">Customer IP Address</div>
                                                                <div className="text-2xl font-bold text-green-400">
                                                                    {authorization.ip}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                                {verified &&
                                                    <div>
                                                        <div className="uppercase text-sm">Signature</div>
                                                        <div>
                                                            <img src={authorization.image_url} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })}

                                    <div className="grid grid-cols-2 mt-8">
                                        <Button danger xs onClick={handleClearAuthorization}>Clear Customer Authorization</Button>
                                    </div>
                                </div>
                            }

                            {(!authorizations || authorizations.length == 0) && <div className="my-5">
                                <div className="grid grid-cols-2">
                                    <Button busy={busy} block onClick={handleSendAuthorization}>Send Authorization Email</Button>
                                </div>
                            </div>}
                        </div>
                    </Panel>}

                    {(state.editable && !state.locked) && <Panel>


                        <div className="my-5">
                            <div className="mb-5">
                                <Button busy={busy} block onClick={handleSave}>Save</Button>
                            </div>
                            {false && <div className="flex items-center cursor-pointer" onClick={() => setNotify(!notify)}>
                                <input className="mr-3" checked={notify} type="checkbox" />
                                <div>Send a notification to the customer?</div>
                            </div>}
                        </div>

                    </Panel>}

                </div>

                <div className="w-4/12">

                    {(!state.customer && state.editable) &&
                        <Panel>
                            <Customer onPick={handleCustomerPick} />
                            <div className="my-5">
                                <a className="cursor-pointer" onClick={toggleCustomerModal}>Create a new Customer</a>
                            </div>
                        </Panel>
                    }

                    {state.customer &&
                        <Panel>

                            {(state.editable && !state.locked) && <div onClick={clearCustomer} className="text-gray-500 hover:text-white absolute m-3 cursor-pointer right-0 top-0">
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                            </div>}

                            <div>
                                <div className="flex">
                                    {state.customer.vip && <div className="text-xs p-0.5 bg-blue-600 rounded-md text-white mr-2">VIP</div>}
                                    <div>{state.customer.first_name} {state.customer.last_name}</div>
                                </div>
                                <div>{state.customer.email}</div>
                                {(state.customer.drivers && state.customer.drivers.length > 0) &&
                                    <>
                                        <div>{state.customer.drivers[0].phone}</div>
                                    </>
                                }
                                {(state.creditBalance != null) && <div className="my-3">
                                    Credit Balance: {formatCurrency(state.creditBalance)}
                                </div>}
                            </div>
                        </Panel>
                    }


                    <div>

                        {((state.editable && !state.locked) && !state.discount) &&
                            <Panel>
                                <div className="mb-5">
                                    {state.discountError &&
                                        <div>
                                            {state.discountError}
                                        </div>
                                    }
                                    <div className="form-group">
                                        <input className="form-control" value={discountCode} onChange={(e) => setDiscountCode(e.target.value)} />
                                    </div>
                                    <Button onClick={handleApplyDiscount}>Apply Discount</Button>
                                </div>
                            </Panel>
                        }


                        {state.discount &&
                            <Panel>
                                <div className="mb-5">

                                    {state.editable && <div onClick={clearDiscount} className="text-gray-500 hover:text-white absolute m-3 cursor-pointer right-0 top-0">
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                                    </div>}

                                    <div>
                                        <div className="font-bold mb-3">Discount Applied</div>
                                        <div>Code: {state.discount.code}</div>
                                        <ul className="text-sm">
                                            {state.discount.fixed &&
                                                <li>{formatCurrency(state.discount.discountAmount)} off</li>
                                            }

                                            {state.discount.percentage &&
                                                <li>{state.discount.percentageAmount}% off</li>
                                            }

                                            {state.discount.priceRule == "all_line_items" &&
                                                <li>entire reservation</li>
                                            }

                                            {state.discount.priceRule == "all_vehicle_line_items" &&
                                                <li>all vehicles</li>
                                            }


                                            {state.discount.priceRule == "specific_vehicle_line_items" &&
                                                <li>all {state.discount.vehicle.name}'s</li>
                                            }

                                            {state.discount.priceRule == "all_product_line_items" &&
                                                <li>all products</li>
                                            }

                                            {state.discount.priceRule == "specific_product_line_items" &&
                                                <li>all {state.discount.product.title}'s</li>
                                            }

                                            {state.discount.trackDateGroup.id &&
                                                <li>on event {state.discount.trackDateGroup.name}'s</li>
                                            }

                                            {state.discount.startsAt &&
                                                <li>starts {formatDate(state.discount.startsAt)}</li>
                                            }

                                            {state.discount.expiresAt &&
                                                <li>ends {formatDate(state.discount.expiresAt)}</li>
                                            }

                                        </ul>
                                    </div>
                                </div>
                            </Panel>
                        }


                    </div>




                    {emails.length > 0 &&
                        <Panel>
                            <div className="font-bold mb-3">Notifications</div>
                            {emails.map((email) => {
                                return (
                                    <a target="new" href={`/emails/${email.id}`} key={email.id} className="text-white block mb-3 border-b border-gray-700 p-1 cursor-pointer hover:bg-gray-700">
                                        <div className="text-xs">
                                            <div>To: {email.to}</div>
                                            <div className="ml-auto">{formatDateShort(email.sent_at)}</div>
                                        </div>
                                        <div className="text-primary-600">
                                            {email.subject}
                                        </div>
                                    </a>
                                )
                            })}
                        </Panel>
                    }


                </div>
            </div>
        </div>
    )
}
