import { Controller } from "stimulus"
const axios = require('axios')
const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}


export default class extends Controller {
  static targets = ["container"]

  connect() {
    console.log("choose payment type")
  }

  async setPaymentMethod(event) {
    //let r = await client.delete(event.currentTarget.dataset.checkinDeleteUrl)
    let client = getClient()
    let r = await client.post(event.currentTarget.dataset.paymentMethodUrl)
    this.containerTarget.innerHTML = r.data
  }

}