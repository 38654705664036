import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import { formatDate, useDebounce, formatCurrency,getVehicleImageUrl } from '../../src/utils'
import {ActionType} from '../types/actions'
const classNames = require("classnames")



interface TrackDateVehicleProps {
    trackDates: TrackDate[],
    onPick: Function
}

export default function TrackDateVehicles({ trackDates, onPick }:TrackDateVehicleProps) {

    const { state, dispatch } = useReservationContext()

    function handlePick(trackDate:TrackDate, trackDateVehicle:TrackDateVehicle) {
        if (onPick) {
            
            let countAvailable = trackDateVehicle.max - trackDateVehicle.reservations_counter
            let inCartCount = state.lineItems.reduce((acc,lineItem)=>{
                if(
                    (lineItem.trackDateVehicle.vehicle.id == trackDateVehicle.vehicle.id) &&
                    (lineItem.trackDateVehicle.track_date_id == trackDateVehicle.track_date_id)
                ) {
                    acc += 1
                }
                return acc
            },0)


            if(inCartCount < countAvailable) {
                onPick(trackDate, trackDateVehicle)
            }


        }
    }

    return (
        <div>
            {trackDates.map((trackDate, index) => {
                return (
                    <div key={index}>
                        <div className="mb-5">
                            <div>{formatDate(trackDate.date)}</div>
                            <div className="font-bold text-l">
                                {trackDate.track.name}
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-1">
                            {trackDate.trackDateVehicles.map((tdv) => {

                                let countAvailable = tdv.max - tdv.reservations_counter
                                let isAvailable = countAvailable > 0
                                let inCartCount = state.lineItems.reduce((acc,lineItem)=>{
                                    if(
                                        (lineItem.trackDateVehicle.vehicle.id == tdv.vehicle.id) &&
                                        (lineItem.trackDateVehicle.track_date_id == tdv.track_date_id)
                                    ) {
                                        acc += 1
                                    }
                                    return acc
                                },0)

                                let canAddToCart = (countAvailable >= (countAvailable + inCartCount))

                                let classList = classNames("fade-in flex flex-row items-center m-1 p-1 border rounded border-gray-700 text-sm relative",{
                                    "cursor-not-allowed	": !isAvailable,
                                    "cursor-pointer hover:bg-gray-800": canAddToCart
                                })


                                return (
                                    <div onClick={() => handlePick(trackDate, tdv)} key={tdv.id} className={classList}>

                                        {!isAvailable && <div className="absolute bg-black bg-opacity-75  inset-0 flex items-center justify-center">
                                                <div className="bg-black rounded-xl shadow-xl p-3">Not Available</div>
                                        </div>}

                                        {inCartCount > 0 && <div className="absolute right-0 top-0">
                                            <div className="bg-primary-600 w-6 h-6 flex items-center justify-center  text-black p-1 m-2 text-xs rounded-full">{inCartCount}</div>
                                        </div>}


                                        <div className="flex items-center mr-2">
                                            <div className="w-32">
                                                <img src={getVehicleImageUrl(tdv)} />
                                            </div>
                                        </div>
                                        <div>
                                            <div>{tdv.vehicle.name}</div>
                                            <div>{tdv.vehicle.display_name}</div>
                                            <div>{formatCurrency(tdv.price)}</div>
                                            <div className="text-primary-600">{tdv.max - tdv.reservations_counter} available</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
