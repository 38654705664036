import { Controller } from "stimulus"
import SignaturePad from 'signature_pad'


export default class extends Controller {
  static targets = ["canvas","input","container"]

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget,{
        penColor: "white",
        onEnd: this.changed.bind(this)
    });

    this.resizeObserver = new ResizeObserver(entries=>{
      this.canvasTarget.width = entries[0].contentRect.width
      this.signaturePad.clear()
    })

    this.resizeObserver.observe(this.containerTarget);
  }

  changed() {
      this.inputTarget.value = this.signaturePad.toDataURL()
  }


  clear() {
    this.signaturePad.clear()
  }

}