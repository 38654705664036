import classNames from 'classnames';
import { eachMonthOfInterval, eachQuarterOfInterval, eachYearOfInterval, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import BookingsSlideOver from './BookingsSlideOver';

export function formatCurrency(money) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money).replace(/\D00$/, '');
}


enum PivotType {
    Month = "MONTH",
    Quarter = "QUARTER",
    Year = "YEAR"
}

function getSlug(date,pivot) {

    if(pivot == PivotType.Year) {
        return format(date,"yyy")
    }

    if(pivot == PivotType.Quarter) {
        return format(date,"QQQ")
    }

    if(pivot == PivotType.Month) {
        return format(date,"yyy-MM-'01'")
    }
    return null
}

export default function TotalRevenue({ bookings, targets }) {

    let [data, setData] = useState([])
    let [current, setCurrent] = useState()
    let [today,setToday] = useState<string>()
    let [pivot,setPivot] = useState<PivotType>(PivotType.Quarter)
    let [showBookings,setShowBookings] = useState(false)
    let [items,setItems] = useState([])
    let [total,setTotal] = useState(0)
    let [targetTotal,setTargetTotal] = useState(0)

    useEffect(() => {

        if (!bookings) {
            return
        }

        console.log("pivot=", pivot)

        setToday(getSlug(new Date(), pivot))


        let intervals = eachMonthOfInterval({ start: new Date(2021, 0, 1), end: new Date(2021, 11, 1) })
        if(pivot == PivotType.Quarter) {
            intervals = eachQuarterOfInterval({ start: new Date(2021, 0, 1), end: new Date(2021, 11, 1) })
        }
        if(pivot == PivotType.Year) {
            intervals = eachYearOfInterval({ start: new Date(2021, 0, 1), end: new Date(2021, 11, 1) })
        }

        let dates = intervals.reduce((acc, d) => {
            let slug = getSlug(d,pivot)
            let target = 0

            if(pivot == PivotType.Month) {
                target = targets?.find(x => x.month == slug)?.revenue_target
            }

            if(pivot == PivotType.Quarter) {
                target = targets?.filter((x)=>{
                    let date = parseISO(x.month)
                    let pslug = getSlug(date,pivot)
                    return slug == pslug
                }).reduce((acc,t)=>{
                    return acc += parseFloat( t.revenue_target )
                },0)
            }            

            if(pivot == PivotType.Year) {
                target = targets?.filter((x)=>{
                    let date = parseISO(x.month)
                    return date.getFullYear() == d.getFullYear()
                }).reduce((acc,t)=>{
                    return acc += parseFloat( t.revenue_target )
                },0)
            }          

            acc[slug] = {
                slug: slug,
                date: d,
                total: 0,
                bookings: [],
                target: target
            }
            return acc
        }, {})

        let targetsByMonth: any = Object.values(bookings.reduce((acc, r) => {

            for (let li of r.line_items) {
                let bookingDate = parseISO(li.date)
                let month = getSlug(bookingDate,pivot)
                acc[month].total += parseFloat( li.sub_total ) 
                acc[month].bookings.push({
                    customer: r.customer,
                    ...li
                })
            }
            return acc            


        }, dates))

        setData(targetsByMonth)
        setTargetTotal(targets.reduce((acc,x)=> acc += parseFloat(x.revenue_target) ,0))
        setTotal(bookings.reduce((acc,r)=> {

            for (let li of r.line_items) {
                acc += parseFloat(li.sub_total)
            }            

            return acc
        },0))


    }, [bookings, targets, pivot])

    function toggleBookings() {
        setShowBookings(!showBookings)
    }

    function displayBookings( bookings ) {
        setShowBookings(true)
        setItems(bookings)
    }

    return (
        <div>
            <div>
                <BookingsSlideOver open={showBookings} onClose={toggleBookings} bookings={items} />
            </div>

            <div className="flex mb-5 items-center">
                <div className="text-xl font-bold">A&amp;D Total Revenue</div>                
                <div className="ml-auto flex">

                    <div className={classNames("p-1 mr-2 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Month),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Month)
                    })} onClick={(e) => setPivot(PivotType.Month)}>Month</div>

                    <div className={classNames("p-1 mr-2 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Quarter),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Quarter)
                    })}  onClick={(e) => setPivot(PivotType.Quarter)}>Quarter</div>

                    <div className={classNames("p-1  mr-2 text-xs text-center border border-gray-400 rounded-md cursor-pointer",{
                        "bg-gray-400 text-gray-900 hover:bg-gray-600": (pivot == PivotType.Year),
                        "hover:bg-gray-400 hover:text-gray-100": !(pivot == PivotType.Year)
                    })}  onClick={(e) => setPivot(PivotType.Year)}>Year</div>

                </div>
            </div>

            <div className="grid grid-cols-4 gap-1 bg-gray-900 text-sm">
                <div>Month</div>
                <div>Current</div>
                <div>Target</div>
                <div>
                    Progress
                </div>
            </div>
            {data.filter(x=>x.slug == today).map((item,i) => {

                let month = item.date
                let target = item.target
                let label = format(month, "MMMM")

                if(pivot == PivotType.Quarter) {
                    label = format(month, "QQQQ")
                }

                if(pivot == PivotType.Year) {
                    label = format(month, "yyy")
                }                

                return (
                    <div onClick={()=>{
                        displayBookings(item.bookings)
                    }}  key={i} className="grid grid-cols-4 gap-1 py-2 text-xl font-bold text-white bg-gray-800 cursor-pointer hover:bg-gray-800">
                        <div>{label}</div>
                        <div>{formatCurrency(item.total)}</div>
                        <div>{target && <span>{formatCurrency(target)}</span>}</div>
                        <div>
                            {target && 
                                <>
                                    {Math.ceil((item.total / target) * 100)}%
                                </>
                            }
                        </div>
                    </div>
                )
            })}
            {data.map((item,i) => {

                let month = item.date
                let target = item.target
                let label = format(month, "MMMM")

                if(pivot == PivotType.Quarter) {
                    label = format(month, "QQQQ")
                }

                if(pivot == PivotType.Year) {
                    label = format(month, "yyy")
                }

                return (
                    <div onClick={()=>{
                        displayBookings(item.bookings)
                    }} key={i} className="grid grid-cols-4 gap-1 text-sm border-b border-gray-800 cursor-pointer hover:bg-gray-800">
                        <div>{label}</div>
                        <div>{formatCurrency(item.total)}</div>
                        <div>{target && <span>{formatCurrency(target)}</span>}</div>
                        <div>
                            {target &&
                                <>
                                    {Math.ceil((item.total / target) * 100)}%
                                </>
                            }
                        </div>
                    </div>
                )
            })}
            <div className="grid grid-cols-4 gap-1 text-sm font-bold">
                <div>Grand Total</div>
                <div>{formatCurrency(total)}</div>
                <div>{formatCurrency(targetTotal)}</div>
                <div>
                    {Math.ceil((total / targetTotal) * 100)}%
                </div>
            </div>            
        </div>
    )
}