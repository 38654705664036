import React, { useEffect, useState } from "react";


export default function TopTracks({ bookings }) {

    const [data,setData] = useState([])
    const [total,setTotal] = useState(0)

    useEffect(()=>{

        if(!bookings) {
            return null
        }

        let total = 0
        let topTracks = Object.values(bookings.reduce((acc,b)=>{
            
            for(let lineItem of b.line_items) {
                if(!acc[lineItem.track.id]) {
                    acc[lineItem.track.id] = {
                        track: lineItem.track,
                        total: 0
                    }
                }
                acc[lineItem.track.id].total += 1
                total += 1
            }

            return acc
        },{}))


        setData(topTracks)
        setTotal(total)

    },[bookings])



    return (
        <div >
            <div className="text-xl font-bold mb-5">Top Tracks</div>
            <div>
                {data.map((item) => {

                    let perc = ((item.total / total) * 100).toFixed(0)

                    return (
                        <div key={item.track.name} className="grid grid-cols-5 text-sm border-b border-gray-800">
                            <div className="col-span-4">{item.track.name}</div>
                            <div className="text-right">{perc}%</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}