import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import { useForm } from "react-hook-form";
import client from "../../src/client"
import Button from '../Button'

export default function CancelForm({onSave}) {

    const { state, dispatch } = useReservationContext()
    const [reason,setReason] = useState('')
    const { register, handleSubmit, watch, errors, reset, setValue } = useForm();
    const [serverErrors,setErrors] = useState()
    const [busy,setBusy] = useState(false)
    const [notify,setNotify] = useState(true)
    
    const onSubmit = async (data) => {
        console.log("data=", data)

        setBusy(true)

        try {
            let r = await client.post(`/api/v1/reservations/${state.id}/cancel`,{
                reason,
                notify_customer: notify
            })

            if(onSave) {
                onSave(r.data)
                
            }
    
        } catch(e) {
            if(e.isAxiosError) {
                console.error(e.response.data.errors)
                setErrors(e.response.data.errors)
            } else {
                console.error(e)
            }
            setBusy(false)
        }
              
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>


            {(serverErrors != null) &&
                <div className="alert alert-error mb-6" role="alert">
                    <div className="flex items-start justify-start">
                        <div>
                        <h4 className="font-black text-sm uppercase tracking">
                            Validation Errors
                        </h4>
                            <ul>
                                {serverErrors.map((error)=>{
                                    return (
                                        <li>{error}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>                
            }

            <div>
                <div className="form-group">
                    <label>Reason for Cancellation</label>
                    <textarea placeholder="Reason for cancellation" value={reason} onChange={(e)=>setReason(e.target.value)} className="form-control"></textarea>
                </div>


                <div className="form-group flex justify-between">
                    <Button busy={busy} block submit>Cancel Reservation</Button>
                </div>                
                <div className="flex items-center cursor-pointer" onClick={()=>setNotify(!notify)}>
                    <input className="mr-3" checked={notify}  type="checkbox"/>
                    <div>Send a notification to the customer?</div>
                </div>                                

            </div>
        </form>
    )
}