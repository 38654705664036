import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "form","drivers"]

  connect() {
    console.log("connect",this.data.get("from"))
  }


  driverChanged(event) {
      
    //console.log(this.data.get("from"))

    if(event.target.value == 'new') {
      Turbolinks.visit(`/booking/drivers/new?from=${this.data.get('from')}`)
    } else {
      Rails.fire(this.formTarget, "submit")
    }

    //this.formTarget.submit()
  }

}