import React, { useReducer, useContext, createContext, useState, useEffect } from "react"


export default function Errors({errors}) {

    if(!errors) {
        return null
    }

    console.log("Error.errors=", errors)

    return (
        <div className="alert alert-error mb-6" role="alert">
        <div className="flex items-start justify-start">
            <div>
            <h4 className="font-black text-sm uppercase tracking">
                Validation Errors
            </h4>
                <ul>
                    {errors.map((error)=>{
                        return (
                            <li>{error}</li>
                        )
                    })}
                </ul>
            </div>
        </div>
    </div>                
    )
}