import { useState, useEffect } from 'react';
import { format, parseISO } from "date-fns";
const classNames = require("classnames")

export function formatDateShort(date) {

  if (typeof date !== Date) {
    date = parseISO(date)
  }

  return format(date, "PPpp")

}

export function getUnitPrice(trackDateProduct, lineItem) {


  let unitPrice = trackDateProduct.product.price

  if (trackDateProduct.product.pricedByVehicle) {
    let vehicleProduct = lineItem.trackDateVehicle.vehicle.vehicleProducts.find(x => x.product.id == trackDateProduct.product.id)
    if (vehicleProduct) {
      unitPrice = vehicleProduct.price
    }
  }
  if (trackDateProduct.product.pricedByTrackDate) {
    unitPrice = lineItem.trackDate.trackDateGroup.registrationFee
  }


  return unitPrice
}

export function formatDateLong(date) {

  if (typeof date !== Date) {
    date = parseISO(date)
  }

  return format(date, "PPpp")

}

export function formatDate(date) {

  if (typeof date !== Date) {
    date = parseISO(date)
  }

  return format(date, "PPPP")

}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function formatCurrency(money) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money);
}

export function getVehicleImageUrl(tdv) {
  try {
    return tdv.vehicle.wp_data.featuredImage.node.mediaItemUrl
  } catch (e) {
    return "/unknown_car.png"
  }
}

export function getReservationStateColor(reservation) {

  let classList = classNames("bg-blue-400", {
    "bg-green-600": reservation.balanceDue == 0,
    "bg-yellow-600": reservation.balanceDue > 0,
    "bg-red-600": reservation.cancelledAt || reservation.voidedAt,
  })

  return classList
}