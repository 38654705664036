import React, {Fragment, useContext,useState,useEffect,useRef } from 'react';
const classNames = require("classnames")

export default function CurrencyField({value,onChange,onBlur,small}) {

    let inputClassList = classNames("",{
        "px-7 form-control": !small,
        "text-xs pl-6 pr-2 text-black w-16 rounded-md p-1 text-center": small
    })

    let prefixClassList = classNames("text-gray-500 sm:text-sm sm:leading-5",{
        "text-xs": small
    })    

    let suffixClassList = classNames("text-gray-500 sm:text-sm sm:leading-5",{
        "text-xs": small
    })

    function handleBlur(e) {
        if(onBlur) {
            onBlur(e)
        }
    }

    return (

        <div className="relative rounded-md shadow-sm w-32">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <span className={prefixClassList}>
                    $
        </span>
            </div>
            <input value={value} onChange={onChange} onBlur={handleBlur} className={inputClassList} placeholder="0.00" />
            <div className="hidden absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className={suffixClassList} id="price-currency">
                    USD
                </span>
            </div>
        </div>

    )
}