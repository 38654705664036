import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    
  }

  onChange(e) {
    let value = Math.abs(parseFloat(e.target.value.replace(/[^\d\.\-]/g, ""))).toFixed(2)
    let balance = parseFloat(this.data.get("balance"))
    if(value <= balance) {
      e.target.value = value
    } else {
      e.target.value = balance
    }
  }

  payInFull(e) {
    this.inputTarget.value = this.data.get("balance")
    this.inputTarget.dispatchEvent(new Event("change"));
  }

}
