import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import { formatDate, useDebounce, formatCurrency,getUnitPrice } from '../../src/utils'
import { ActionType } from '../types/actions'
import CurrencyField from '../CurrencyField'
import Button from '../Button'

export enum TargetTypes {
    Reservation = "RESERVATION",
    DraftLineItem = "DRAFT_LINE_ITEM",
    Checkin = "CHECKIN"
}

interface ProductLineItemFormProps {
    editable?: boolean;
    lineItemIndex?:number;
    target: TargetTypes,
    checkinId?:number;
}


export default function ProductLineItemForm({lineItemIndex,editable,target,checkinId}:ProductLineItemFormProps) {

    const { state, dispatch } = useReservationContext()
    let lineItem = null

    if(target == TargetTypes.Reservation) {
        lineItem = state.lineItems[lineItemIndex]
    }

    if(target == TargetTypes.DraftLineItem) {
        lineItem = state.draftLineItem
    }

    if(target == TargetTypes.Checkin) {
        lineItem = state.draftLineItem
        editable = true
    }

    function handleQuantityChange(e,trackDateProduct,unitPrice) {
        dispatch({
            type: ActionType.UpdateAddonQuantity,
            target: target,
            lineItemIndex: lineItemIndex,
            trackDateProduct: trackDateProduct,
            quantity: e.target.value,
            unitPrice: unitPrice
        })
    }

    function handlePriceChange(e,trackDateProduct) {
        dispatch({
            type: ActionType.UpdateAddonPrice,
            target: target,
            lineItemIndex: lineItemIndex,
            trackDateProduct: trackDateProduct,
            price: e.target.value
        })        
    }

    function handleAddProduct(trackDateProduct) {
        dispatch({
            type: ActionType.AddProductToLineItem,
            target: target,
            lineItemIndex: lineItemIndex,
            trackDateProduct: trackDateProduct
        })        
    }

    function handleRemoveProduct(trackDateProduct) {
        dispatch({
            type: ActionType.UpdateAddonQuantity,
            target: target,
            lineItemIndex: lineItemIndex,
            trackDateProduct: trackDateProduct,
            quantity: 0
        })
    }

    return (

        <div className="rounded-md">

            {(lineItem && lineItem.lineItems && lineItem.lineItems.length == 0) &&
                <div className="mb-1 p-2 text-center text-gray-400 text-sm">
                    <div className="font-bold">Nothing Selected</div>
                    <div>
                        Add products from the list below to get started
                    </div>
                </div>
            }


            {(lineItem && lineItem.lineItems) && lineItem.lineItems.map((productLineItem) => {

                let unitPrice = productLineItem.unitPrice
                let quantity = productLineItem.quantity 
                let trackDateProduct = productLineItem.trackDateProduct
                let subTotal = unitPrice * quantity


                return (
                    <div key={trackDateProduct?.id} className="text-xs flex p-2 items-center border-b hover:bg-gray-800">
                        <div className="w-7/12">
                            <div className="p-2">{trackDateProduct.product.title}</div>
                        </div>
                        <div className="w-2/12 flex items-center">
                            {editable &&
                                <>
                                    {<CurrencyField small onChange={(e) => {
                                        handlePriceChange(e,trackDateProduct)
                                    }} className="" value={unitPrice} />}

                                </>
                            }
                            {!editable &&
                                <>
                                    <span>{formatCurrency(unitPrice)}</span>
                                </>
                            }
                            <div className="mx-2">x</div>
                        </div>
                        <div className="w-1/12">
                            {editable &&
                                <input onChange={(e) => {
                                    handleQuantityChange(e,trackDateProduct,unitPrice)
                                }} className="text-black w-10 rounded-md p-1 text-center" value={quantity} />
                            }
                            {!editable &&
                                <span>{quantity}</span>
                            }
                        </div>
                        <div className="w-1/12">
                            <div className="p-2">{formatCurrency(subTotal)}</div>
                        </div>
                        {editable && <div className="w-1/12 p-2 flex justify-end text-red-600 cursor-pointer hover:text-red-300 hover:font-bold" onClick={()=>{
                            handleRemoveProduct(trackDateProduct)
                        }}>
                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                        </div>}
                    </div>
                )
            })}
            
            {editable && <div className="my-5 p-3 overflow-y-scroll always-show-scrollbar" style={{height:400}}>
                {lineItem.trackDate.trackDateProducts.map((trackDateProduct) => {

                    let addOnLineItem = lineItem.lineItems.find(x => x.trackDateProduct.product.id == trackDateProduct.product.id)
                    let quantity = (addOnLineItem) ? addOnLineItem.quantity : 0

                    if (quantity > 0) {
                        return null
                    }

                    let unitPrice = getUnitPrice(trackDateProduct,lineItem)

                    return (
                        <div key={trackDateProduct.id} onClick={(e)=>handleAddProduct(trackDateProduct)} className="flex items-center text-xs p-2 bg-gray-800 rounded-md mb-1 hover:bg-gray-700 cursor-pointer">
                            <div className="w-7/12">{trackDateProduct.product.title}</div>
                            <div>
                                {formatCurrency(unitPrice)}
                            </div>
                            <div className="ml-auto hover:text-green-400">
                                <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" /></svg>
                            </div>
                        </div>
                    )
                })}
            </div>}

        </div>
    )
}