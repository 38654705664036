import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import FinalizeCheckout from './FinalizeCheckout'
import {Provider} from './Provider'

export default function ReservationScreen(props) {
    return (
        <Provider checkinId={props.id} editable={props.editable}>
            <FinalizeCheckout {...props} />
        </Provider>
    )
}