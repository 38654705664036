import { Controller } from "stimulus"
import flatpickr from "flatpickr";


export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    console.log("init booking calendar")
    flatpickr(this.inputTarget, {});

  }

}
