import React, { useEffect, useState } from 'react';
import SlideOver from '../SlideOver';

export function formatCurrency(money) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(money).replace(/\D00$/, '');
}

export default function BookingsSlideOver({ open, onClose, bookings }) {

    const [total,setTotal] = useState(0)
    const [gross,setGross] = useState(0)

    useEffect(()=>{

        if(!bookings) {
            return null
        }

        setGross(bookings.reduce((acc,x)=> acc += parseFloat(x.sub_total) ,0))
        setTotal(bookings.reduce((acc,x)=> acc += 1 ,0))



    },[bookings])
    

    return (
        <SlideOver title="Bookings" open={open} onClose={onClose}>

            <div className="text-black flex  mb-5">
                <div className="w-32">
                    <div className="text-2xl font-bold">{formatCurrency(gross)}</div>                    
                    <div>Gross</div>
                </div>
                <div>
                    <div className="text-2xl font-bold">{total}</div>
                    <div>Total</div>                    
                </div>
            </div>

            <div className="text-black">
                {bookings && bookings.map((item) => {
                    return (
                        <div key={item.id} className="flex text-xs items-center mb-2 border-b border-gray-200">
                            <div>
                                <div>#{item.reservation_id} {item.customer.name}</div>
                                <div>{item.date} - {item.track.name}</div>
                                <div>{item.vehicle.name}</div>
                            </div>
                            <div className="ml-auto">{formatCurrency(parseFloat(item.sub_total))}</div>
                        </div>
                    )
                })}

            </div>

        </SlideOver>
    )
}