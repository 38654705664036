import { Controller } from "stimulus"
const Atrament = require('atrament')
//let drawingDimensions = "1234 × 1542 pixels"

export default class extends Controller {
  static targets = ["canvas","radio","input","container"]

  connect() {

    this.resizeObserver = new ResizeObserver(entries=>{
        console.log("resize observer fired")
        let width = entries[0].contentRect.width
        this.canvasTarget.width = width
        this.loadImageToCanvas(width)
    })
  
    this.resizeObserver.observe(this.containerTarget);

  }

  radioChanged(event) {
      if(event.currentTarget.value == 'no_damage') {
          this.containerTarget.classList.add('hidden')
      } else {
         this.containerTarget.classList.remove('hidden')
      }
  }

  loadImageToCanvas() {
    this.image = new Image()
    this.image.onload = () => {

        let ratio = this.canvasTarget.width / this.image.width
        let height = this.image.height * ratio
        this.canvasTarget.height = height

        this.setupSketchpad()
        this.drawVehicleImage()

    }
    this.image.src = this.data.get("background-image")
  }

  setupSketchpad(){
    this.sketchpad = new Atrament(this.canvasTarget);
    this.sketchpad.smoothing = 0.5;
    this.sketchpad.weight = 5
    this.sketchpad.color = "red"
    this.sketchpad.recordStrokes = true;
    this.sketchpad.adaptiveStroke = false
    this.sketchpad.addEventListener('strokerecorded', this.recordStroke.bind(this))
  }

  drawVehicleImage() {
    let width = this.canvasTarget.width
    let height = this.canvasTarget.height
    let context = this.canvasTarget.getContext("2d");
    context.drawImage(this.image, 0, 0, width, height);
  }

  recordStroke({stroke}) {
    this.inputTarget.value = this.sketchpad.toImage()
  }

  clearCanvas() {
      this.sketchpad.clear()
      this.drawVehicleImage()
  }

}