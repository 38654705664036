import { Controller } from "stimulus"
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}

export default class extends Controller {
  static targets = ['kanban']

  connect() {
    console.log("connect checkin")
  }


  async cancelCheckin(event) {
    let client = getClient()
    let r = await client.delete(event.currentTarget.dataset.checkinDeleteUrl)
    this.kanbanTarget.outerHTML = r.data
  }

  async cancelCheckout(event) {
    let client = getClient()
    let r = await client.patch(event.currentTarget.dataset.checkinCancelUrl)
    this.kanbanTarget.outerHTML = r.data
  }

}