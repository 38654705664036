import React,{ useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
const classNames = require("classnames")
function Portal ({ children}) {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector('#portal')
    setMounted(true)
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}


export default function Modal({children,onClose,title,wide}) {

    function handleClose() {
        if(onClose) {
            onClose()
        }
    }

    let width = wide ? "60rem" : "45rem"

    return (
        <Portal>
            <ScrollLock>
                <div style={{ "transform": "translate(-50%, -50%)" }} className="z-50 fixed h-screen w-screen flex top-1/2 left-1/2 items-center justify-center">
                    <div className="modal-zoom-in bg-gray-900 rounded-xl" style={{width: width}}>
                        {title && 
                            <div className="mb-5 text-white bg-gray-800 rounded-t-xl shadow-xl text-center p-3">{title}</div>
                        }
                        {children}
                    </div>
                    <div onClick={handleClose} style={{ zIndex: -1 }} className="backdrop-fade-in blur fixed inset-0 bg-black bg-opacity-50 "></div>
                </div>
            </ScrollLock>
        </Portal>
    )
}