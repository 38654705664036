import axios from 'axios'
import React, { useEffect, useState } from "react"
import Bookings from './Bookings'
import TopCars from './TopCars'
import TopCustomers from './TopCustomers'
import TopProducts from './TopProducts'
import TopTracks from './TopTracks'
import TotalRevenue from './TotalRevenue'


export default function Dashboard({ payment }) {


    const [bookings,setBookings] = useState()
    const [targets,setTargets] = useState()

    async function loadData() {

        let tr = await axios.get('/dashboard/targets')
        setTargets(tr.data)
        
        
        let r = await axios.get('/dashboard/data')
        setBookings(r.data)


    }



    useEffect(() => {
        loadData()
    }, [])


    return (
        <div>


            <div className="grid grid-cols-5 gap-5 mb-10">

                <div className="col-span-2">
                    <Bookings bookings={bookings} targets={targets} />
                </div>

                <div className="col-span-2">
                    <TotalRevenue bookings={bookings} targets={targets} />
                </div>

                <div>
                    <div className="mb-5">
                        <TopTracks bookings={bookings} />
                    </div>
                </div>

            </div>


            <div className="mb-10">
                <TopCars bookings={bookings} />
            </div>

            <div className="mb-10">
                <TopProducts bookings={bookings} />
            </div>

            <div className="mb-10">

                <TopCustomers bookings={bookings} />

            </div>

        </div>
    )
}