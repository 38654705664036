import { Controller } from "stimulus"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


export default class extends Controller {
  static targets = [ "backdrop","panel"]

  connect() {
    this.locked = false
    document.onkeydown = this.handleEsc.bind(this)
  }

  handleEsc(e) {
    e = e || window.event;
    if (e.keyCode == 27 && this.locked === true) {
        this.toggle()
    }
  }

  toggle() {
    
    if(this.locked === true) {
      clearAllBodyScrollLocks()
    } else {
      disableBodyScroll(document.body)
    }

    this.locked = !this.locked
    this.backdropTarget.classList.toggle('hidden')
    this.panelTarget.classList.toggle('hidden')
  }


}