import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form","results" ]

  connect() {
    
  }

  onChange() {
      console.log("search form changed")
      Rails.fire(this.formTarget, "submit")
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.resultsTarget.innerHTML = xhr.response
  }  


}
