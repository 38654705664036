import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import { useReservationContext } from './Provider'
import client from "../../src/client"
import { formatDate, useDebounce, formatCurrency } from '../../src/utils'

export default function Customer({ onPick }) {

    const [search, setSearch] = useState('');
    const [results, setResults] = useState()
    const [index, setIndex] = useState()
    const debouncedSearch = useDebounce(search, 200);

    async function searchCustomers() {
        let r = await client.get(`/api/v1/customers/search?q=${search}`)
        setResults(r.data)
    }

    useEffect(() => {
        console.log("debouncedSearch=", debouncedSearch)
        searchCustomers()
    }, [debouncedSearch])

    function handleSelect(customer) {
        if (onPick) {
            onPick(customer)
        }
    }

    return (
        <div>
            <div className="mb-2">Find or create a customer</div>
            <div>
                <input value={search} onChange={(e) => setSearch(e.target.value)} className="p-3 rounded-md w-full text-black" placeholder="Search Customers" />
                <div className="relative">
                    {results && <div className="absolute top-0 left-0 right-0 z-50 my-2 shadow-xl rounded-lg bg-gray-800">
                        {results.map((customer) => {
                            return (
                                <div onClick={() => handleSelect(customer)} key={customer.id} className="p-3 border-b border-gray-600 cursor-pointer hover:bg-gray-700">
                                    <div>{customer.first_name} {customer.last_name}</div>
                                    <div>{customer.email}</div>
                                </div>
                            )
                        })}
                    </div>}
                </div>
            </div>
        </div>
    )
}
