import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "vehicle" ]

  connect() {
    
  }

}
