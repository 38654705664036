import { Controller } from "stimulus"
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}

export default class extends Controller {
  static targets = ['priceRule','vehicleSelect','productSelect','fixedAmount','percentageAmount']

  connect() {
    
  }

  discountTypeChanged(e) {
    console.log(e.currentTarget.value)  

    this.fixedAmountTarget.classList.add('hidden')
    this.percentageAmountTarget.classList.add('hidden')

    if(e.currentTarget.value == 'fixed') {
        this.fixedAmountTarget.classList.remove('hidden')
    }

    if(e.currentTarget.value == 'percentage') {
        this.percentageAmountTarget.classList.remove('hidden')
    }    
    
    
  }

  priceRuleChanged(e) {
    console.log(e.currentTarget.value)

    this.vehicleSelectTarget.classList.add('hidden')
    this.productSelectTarget.classList.add('hidden')

    if(e.currentTarget.value == 'specific_vehicle_line_items') {
        this.vehicleSelectTarget.classList.remove('hidden')
    }

    if(e.currentTarget.value == 'specific_product_line_items') {
        this.productSelectTarget.classList.remove('hidden')
    }

  }

}