import React, { useState } from "react"
import { formatCurrency, formatDate } from '../../src/utils'
import Button from '../Button'
import { ActionType } from "../types/actions"
import Modal from './Modal'
import PaymentRow from "./PaymentRow"
import { TargetTypes } from "./ProductLineItemForm"
import { useReservationContext } from './Provider'
import RefundForm from "./RefundForm"
import TransactionForm from "./TransactionForm"
const classNames = require("classnames")


export default function FinalizeCheckout() {

    const { state, dispatch, reloadCheckin,cancelTransaction } = useReservationContext()
    const [showTransactionModal, setShowTransactionModal] = useState(false)

    if (!state.checkin) {
        return (
            <div>
                Loading Checkin
            </div>
        )
    }

    return (
        <div>
            <div>



                <div className="grid grid-cols-4 mb-10">
                    <div>
                        <div className="uppercase text-sm">
                            Total Fuel Price</div>
                        <div className="text-2xl font-bold">
                            {formatCurrency(state.checkin.totalPrice)}
                            <div className="text-sm text-gray-400">
                                {state.checkin.gallonsUsed}/gal x {formatCurrency(state.checkin.fuelPrice)}
                            </div>
                        </div>

                    </div>

                    <div>
                        <div className="uppercase text-sm">Deductible Applied</div>
                        <div className="text-2xl font-bold">
                            {formatCurrency(state.checkin.deductibleApplied)}
                            <div className="text-sm text-gray-400">
                                Deductible: {formatCurrency(state.checkin.deductible)}
                            </div>                            
                        </div>
                    </div>

                    <div>
                        <div className="uppercase text-sm">Total Price</div>
                        <div className="text-2xl font-bold">{formatCurrency(state.checkin.totalPrice)}</div>
                    </div>

                    <div>
                        <div className="uppercase text-sm">Balance Due</div>
                        <div className="text-2xl font-bold">{formatCurrency(state.checkin.balanceDue)}</div>
                    </div>
                </div>


                {showTransactionModal &&
                    <Modal title="New Transaction" onClose={() => setShowTransactionModal(false)}>
                        <div className="p-5">
                            <TransactionForm target={TargetTypes.Checkin} onSave={async () => {
                                await reloadCheckin()
                                setShowTransactionModal(false)
                            }} />
                        </div>
                    </Modal>
                }

                {state.showRefundModal && 
                <Modal onClose={()=>dispatch({type: ActionType.CloseRefundModal})}>
                    <div className="p-5">
                        <RefundForm onSuccess={async () => {


                        }}/>
                    </div>
                </Modal>
            }

                <div className="my-10">
                    {state.checkin.transactions?.map((transaction) => {

                        let payment = null
                        let allowCancel = false
                        let allowRefund = true

                        if (transaction.payments.length > 0) {
                            payment = transaction.payments[0]
                        }

                        if (transaction.payments.length > 1) {
                            payment = transaction.payments[1]
                        }

                        if( payment && payment.refundedAt) {
                            allowRefund = false
                        }

                        return (
                            <div className="text-sm mb-3 rounded-md border border-gray-700">
                                <div className="flex bg-gray-700 text-white p-2">
                                    <div className="mr-2">
                                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" clipRule="evenodd" /></svg>
                                    </div>
                                    <div>Transaction #{transaction.id}</div>
                                    {transaction.draft && <div className="text-sm mx-2 text-green-300 uppercase">
                                        Draft
                                    </div>}                                    
                                    <div className="ml-auto text-gray-200 flex items-center">
                                        {formatDate(transaction.createdAt)}

                                        {!payment &&
                                            <div className="ml-3">
                                                <Button onClick={()=>{
                                                    cancelTransaction(transaction)
                                                }} xs>Cancel</Button>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    {transaction.lineItems.map((tLineItem) => {
                                        return (
                                            <div className="flex items-center p-2">
                                                <div className="w-7/12">{tLineItem.trackDateProduct.product.title}</div>
                                                <div className="w-2/12 flex items-center">
                                                    <span>{formatCurrency(tLineItem.unitPrice)}</span>
                                                    <div className="mx-2">x</div>
                                                </div>
                                                <div className="w-1/12">{tLineItem.quantity}</div>
                                                <div className="w-1/12">{formatCurrency(tLineItem.subTotal)}</div>
                                            </div>
                                        )
                                    })}
                                </div>


                                {payment && <div className="p-2 mb-5">
                                    <div className="text-xs text-center text-gray-200 border-b border-gray-700 p-1 uppercase">Payments</div>
                                    {payment && <div>
                                        <PaymentRow payment={payment} />
                                    </div>}
                                </div>}

                                {(payment && false) && <div className="p-2 mb-5 flex">
                                    <div className="ml-auto flex">

                                        {allowCancel && <div className="mr-2">
                                            <Button xs>Cancel</Button>
                                        </div>}

                                        {allowRefund && <div>
                                            <Button xs onClick={() => {
                                                console.log("refund onclick payment=", payment)
                                                dispatch({ type: ActionType.BeginRefund, payment: payment })
                                            }}>
                                                Refund
                                            </Button>
                                        </div>}

                                    </div>
                                </div>}


                            </div>
                        )
                    })}
                </div>

                <Button onClick={() => setShowTransactionModal(true)}>Accept Payment</Button>

            </div>
        </div>
    )
}