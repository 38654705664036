import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import client from '../src/client'

export default class extends Controller {
  static targets = [ "file","form","progress","label","input" ,"preview","busy","container"]

  connect() {
    addEventListener("direct-upload:initialize", event => {
      
    })

    addEventListener("direct-upload:start", event => {
       
    })

    addEventListener("direct-upload:end", event => {
       
    })
  }

  click(event) {
    this.fileTarget.click()
  }

  async destroy(e) {
      let id = e.currentTarget.dataset.id
      let r = await client.delete(`/checkins/${this.data.get('checkin-id')}/photo/${id}`)
      this.containerTarget.outerHTML = r.data
  }

  fileChanged(event) {
    
      console.log("file changed=", event)
      this.busyTarget.classList.toggle('hidden')
      const file = this.fileTarget.files[0]
      const url = this.fileTarget.dataset.directUploadUrl;
      const upload = new DirectUpload(file, url, this);

      upload.create(async (error, blob) => {
          this.busyTarget.classList.toggle('hidden')
          if(!error) {
              let r = await client.post(`/checkins/${this.data.get('checkin-id')}/photo`,{blob_id: blob.signed_id})
              this.containerTarget.outerHTML = r.data
          }
      })

  }


}