import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "form", "drivers", "checkbox","lineItemContainer"]

  connect() {
    console.log("line item controller connected to ", this.formTarget)
  }

  driverChanged(event) {
    if(event.target.value == 'new') {
      Turbolinks.visit(`/drivers/new?reservation_id=${this.data.get('id')}&line_item_id=${this.data.get('line-item-id')}`)
    } else {
      Rails.fire(this.formTarget, "submit")
    }
  }  

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.lineItemContainerTarget.innerHTML = xhr.response

    this.lineItemContainerTargets.map((el)=>{
      el.classList.remove('pointer-events-none')
      el.classList.remove('opacity-50')
    })    
  }  

  productChanged(event) {

    this.lineItemContainerTargets.map((el)=>{
      el.classList.add('pointer-events-none')
      el.classList.add('opacity-50')
    })

    Rails.fire(this.formTarget, 'submit');
  }  

}