import React, { useReducer, useContext, createContext, useState, useEffect } from "react"
import Reservation from './Reservation'
import {Provider} from './Provider'

export default function ReservationScreen(props) {
    return (
        <Provider id={props.id} editable={props.editable}>
            <Reservation {...props} />
        </Provider>
    )
}