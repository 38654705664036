import { Controller } from "stimulus"
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}


export default class extends Controller {
  static targets = [ "vehicleRow","input","vehicleName","vehicleHighlight", "dateHighlight", "productHighlight" ]

  connect() {
    console.log("track date groups controller")
  }

  onVehiclePriceChange(e) {

      let date = e.target.dataset.date
      let vehicleId = e.target.dataset.vehicle
      let id = e.target.dataset.id
      let client = getClient()

      console.log(date,vehicleId)

      client.patch(`/track_date_groups/${id}/vehicle/price`,{price: e.target.value,date: date,vehicleId: vehicleId})

  }

  onVehicleQuantityChange(e) {
    let date = e.target.dataset.date
    let vehicleId = e.target.dataset.vehicle
    let id = e.target.dataset.id
    let client = getClient()
    console.log(date,vehicleId)

    client.patch(`/track_date_groups/${id}/vehicle/quantity`,{quantity: e.target.value,date: date,vehicleId: vehicleId})
  }  

  onProductPriceChange(e) {

    let date = e.target.dataset.date
    let productId = e.target.dataset.product
    let id = e.target.dataset.id
    let client = getClient()
    
    console.log(date,productId)

    client.patch(`/track_date_groups/${id}/product/price`,{price: e.target.value,date: date,productId: productId})

  }  

  onVehicleOver(e) {
    let vehicleId = e.target.dataset.vehicle

    this.vehicleHighlightTargets.map((el)=>{
        if(el.dataset.vehicle == vehicleId) {
            el.classList.add("bg-gray-600")
        } else {
            el.classList.remove("bg-gray-600")
        }
    })
  }

  onProductOver(e) {
    let productId = e.target.dataset.product

    this.productHighlightTargets.map((el)=>{
        if(el.dataset.product == productId) {
            el.classList.add("bg-gray-600")
        } else {
            el.classList.remove("bg-gray-600")
        }
    })
  }

  onInputOver(e) {
    let date = e.target.dataset.date


    this.dateHighlightTargets.map((el)=>{
        if(el.dataset.date == date) {
            el.classList.add("bg-gray-600")
        } else {
            el.classList.remove("bg-gray-600")
        }
    })

  }

  onProductOut(e) {
    this.productHighlightTargets.map((el)=>{
        el.classList.remove("bg-gray-600")
    })
  }  

  onVehicleOut(e) {
    let vehicleId = e.target.dataset.vehicle
    this.vehicleHighlightTargets.map((el)=>{
        el.classList.remove("bg-gray-600")
    })
  }  

  onInputOut(e) {
    this.dateHighlightTargets.map((el)=>{
        el.classList.remove("bg-gray-600")
    })
  }    
}
