import { Controller } from "stimulus"
import Rails from "@rails/ujs";
const axios = require('axios')
function getClient() {
  const token = document.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}
  const client = axios.create({
    headers: {
      common: {
        'X-CSRF-Token': token.content
      }
    }
  })
  return client  
}

export default class extends Controller {
  static targets = [ "form","checkbox" ,"lineItemContainer","guestInput"]

  connect() {
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.lineItemContainerTarget.innerHTML = xhr.response
  }

  changed(event) {
    //this.formTarget.submit()
    Rails.fire(this.formTarget, 'submit');
  }

  async guestChanged(e) {
    let guests = this.guestInputTargets.map(x=>x.value)
    console.log("guests=", guests)

    let payload = {
      id: e.target.dataset.id,
      guests: guests
    }

    console.log("payload=", payload)

    await getClient().post("/booking/guests", payload)

  }

}